//API imports
import { httpGet } from '../api/httpGet';
import { httpPost } from '../api/httpPost';
import { httpPut } from '../api/httpPut';
import { httpDelete } from '../api/httpDelete';

import { auth } from '../auth/index';
import { itemManager } from '../javascript/itemManager'
import { formatAllAsBudget } from '../javascript/budgetManager'
import { globalFunctions } from '../javascript/globalFunctions';

export const actions = {

  /////////////////////////////////////////////////////////
  //Actions For Updating API 
  /////////////////////////////////////////////////////////

  syncStoreWithDatabaseAfterComingOnline: context => {
    //Write Code to Sync Offline changes With API Here
  },
  makeChangesToAPI: (context, mutationType) => {

    if (mutationType === 'initialiseStore') {
      context.dispatch('getDataFromAPIIfNeeded');
    }
    else if (mutationType.substring(0, 3) === 'add') {
      context.dispatch('addDataForAPIIfNeeded', mutationType)
    }
    else if (mutationType.substring(0, 6) === 'update') {
      context.dispatch('updateDataForAPIIfNeeded', mutationType)
    }
    else if (mutationType.substring(0, 4) === 'mark') {
      context.dispatch('deleteDataFromAPIIfNeeded', mutationType)
    }

  },

  ///GET DATA

  getDataFromAPIIfNeeded: context => {
    //Gets Data from Account If Needed
    context.dispatch('getDataFromAccountIfNeeded')

    //Get Data from Currency Id Needed
    context.dispatch('getDataFromCurrencyIfNeeded')

    //Gets Data from Transaction If Needed
      context.dispatch('getDataFromTransactionIfNeeded')

      //Gets Data from Perios If Needed
      context.dispatch('getDataFromPeriodIfNeeded')

    //Get Data from Item If Needed
    //context.dispatch('getDataFromItemIfNeeded')

    //Get Data from Budget If Needed
    context.dispatch('getDataFromBudgetIfNeeded')

    //Get Data from Category If Needed
    context.dispatch('getDataFromCategoryIfNeeded')

    //Get Data from Place Sold If Needed
    context.dispatch('getDataFromPlaceSoldIfNeeded')

    //Get Data from User If Needed
    context.dispatch('getDataFromUserIfNeeded')
  },

    getDataFromPeriodIfNeeded: context => {
        if (context.state.Settings.length === 0) {
            context.dispatch('getPeriodDataFromAPI')
        }
    },
  getDataFromAccountIfNeeded: context => {
    if (context.state.Accounts.length === 0) {
      context.dispatch('getAccountDataFromAPI')
    }
    },
    getDataFromCurrencyIfNeeded: context => {
        if (context.state.Currencies.length === 0) {
            context.dispatch('getCurrencyDataFromAPI')
        }
    },
  getDataFromTransactionIfNeeded: context => {
    if (context.state.Transactions.length === 0) {
      context.dispatch('getTransactionDataFromAPI')
    }
  },
  //getDataFromItemIfNeeded: context => {
  //  if (context.state.Items.length === 0) {
  //    context.dispatch('getItemDataFromAPI')
  //  }
  //},
  getDataFromBudgetIfNeeded: context => {
    if (context.state.Budgets.length === 0) {
      context.dispatch('getBudgetDataFromAPI')
    }
  },
  getDataFromCategoryIfNeeded: context => {
    if (context.state.Categories.length === 0) {
      context.dispatch('getCategoryDataFromAPI')
    }
  },
    getDataFromPlaceSoldIfNeeded: context => {
        if (context.state.PlaceSold.length === 0) {
            context.dispatch('getPlaceSoldDataFromAPI')
        }
    },
  getDataFromUserIfNeeded: context => {

    //////////////////////////////////////
    //Gets User ID from LocalStorage
    //////////////////////////////////////

    let userId = localStorage.getItem('id_token') 

    if (context.state.User.length === 0) {
      context.dispatch('getUserDataFromAPI', userId)
    }
  },

  ///END OF GET DATA

  ///ADD DATA

  addDataForAPIIfNeeded: (context, mutationType) => {
    
    if (mutationType.indexOf("Account") !== -1) {
    //Gets Data from Account If Needed
      context.dispatch('addDataForAccountIfNeeded')
    }
    else if (mutationType.indexOf("PlaceSold") !== -1) {
      //Gets Data from PlaeSold If Needed
      context.dispatch('addDataForPlaceSoldIfNeeded')
    }
    else if (mutationType.indexOf("Transaction") !== -1) {
      //Gets Data from Transaction If Needed
      context.dispatch('addDataForTransactionIfNeeded')
    }
    else if (mutationType.indexOf("Item") !== -1) {
      //Gets Data from Item If Needed
      context.dispatch('addDataForItemIfNeeded')
    }
    else if (mutationType.indexOf("Budget") !== -1) {
      //Gets Data from Item If Needed
      context.dispatch('addDataForBudgetIfNeeded')
    }
    else if (mutationType.indexOf("Currency") !== -1) {
      //Gets Data from Item If Needed
      context.dispatch('addDataForCurrencyIfNeeded')
    }
    else if (mutationType.indexOf("Category") !== -1) {
      //Gets Data from Category If Needed
      context.dispatch('addDataForCategoryIfNeeded')
      }
  },

  addDataForAccountIfNeeded: context => {
    let newAccounts = []

    for (let i = 0; i < context.state.Accounts.length; i++) {
      if (context.state.Accounts[i].objectState === "New") {
        newAccounts.push(context.state.Accounts[i])
      }
    }

    context.dispatch('addNewAccountToDatabase', newAccounts)

  },
  addDataForTransactionIfNeeded: context => {
    let newTransactions = []

    for (let i = 0; i < context.state.Transactions.length; i++) {
        if (context.state.Transactions[i].objectState === "New") {
            newTransactions.push(context.state.Transactions[i])
      }
    }

      context.dispatch('addNewTransactionToDatabase', newTransactions)

  },
  addDataForItemIfNeeded: context => {
    let newItems = []

    for (let i = 0; i < context.state.Items.length; i++) {
      if (context.state.Items[i].objectState === "New") {
        newItems.push(context.state.Items[i])
      }
    }

    context.dispatch('addNewItemToDatabase', newItems)

  },
  addDataForBudgetIfNeeded: context => {
    let newBudgets = []

    for (let i = 0; i < context.state.Budgets.length; i++) {
      if (context.state.Budgets[i].objectState === "New") {
        newBudgets.push(context.state.Budgets[i])
      }
    }

    context.dispatch('addNewBudgetToDatabase', newBudgets)

  },
  addDataForCurrencyIfNeeded: context => {
    let newCurrencies = []

    for (let i = 0; i < context.state.Currencies.length; i++) {
      if (context.state.Currencies[i].objectState === "New") {
        newCurrencies.push(context.state.Currencies[i])
      }
    }

    context.dispatch('addNewCurrencyToDatabase', newCurrencies)

  },
  addDataForCategoryIfNeeded: context => {
    let newCategories = []

    for (let i = 0; i < context.state.Categories.length; i++) {
      if (context.state.Categories[i].objectState === "New") {
        newCategories.push(context.state.Categories[i])
      }
    }

    context.dispatch('addNewCategoryToDatabase', newCategories)
    },
    addDataForPlaceSoldIfNeeded: context => {
        let newplaces = []

        for (let i = 0; i < context.state.PlaceSold.length; i++) {
            if (context.state.PlaceSold[i].objectState === "New") {
                newplaces.push(context.state.PlaceSold[i])
            }
        }

        context.dispatch('addNewPlaceSoldToDatabase', newplaces)
    },

  ///END OF ADD DATA

  //UPDATE DATA

  updateDataForAPIIfNeeded: (context, mutationType) => {

    //if (mutationType.indexOf("Account") !== -1) {
    //  //Gets Data from Account If Needed
    //  context.dispatch('updateDataForAccountIfNeeded')
    //}
   if (mutationType.indexOf("Transaction") !== -1) {
      //Gets Data from Income If Needed
      context.dispatch('updateDataForTransactionIfNeeded')
    }
    else if (mutationType.indexOf("Item") !== -1) {
      //Gets Data from Item If Needed
      context.dispatch('updateDataForItemIfNeeded')
    }
    else if (mutationType.indexOf("Budget") !== -1) {
      //Gets Data from Item If Needed
      context.dispatch('updateDataForBudgetIfNeeded')
    }

  },

  updateDataForAccountIfNeeded: context => {
    let updateAccounts = []

    for (let i = 0; i < context.state.Accounts.length; i++) {
      if (context.state.Accounts[i].objectState === "Update") {
        updateAccounts.push(context.state.Accounts[i])
      }
    }

    context.dispatch('updateAccountinDatabase', updateAccounts)

  },
    updateDataForTransactionIfNeeded: context => {
    let updateTransactions = []

        for (let i = 0; i < context.state.Transactions.length; i++) {
            if (context.state.Transactions[i].objectState === "Update") {
                updateTransactions.push(context.state.Transactions[i])
      }
    }

    context.dispatch('updateTransactionInDatabase', updateTransactions)

  },
  updateDataForItemIfNeeded: context => {
    let updateItems = []

    for (let i = 0; i < context.state.Items.length; i++) {
      if (context.state.Items[i].objectState === "Update") {
        updateItems.push(context.state.Items[i])
      }
    }

    context.dispatch('updateItemInDatabase', updateItems)

  },
  updateDataForBudgetIfNeeded: context => {
    let updateBudgets = []

    for (let i = 0; i < context.state.Budgets.length; i++) {
      if (context.state.Budgets[i].objectState === "Update") {
        updateBudgets.push(context.state.Budgets[i])
      }
    }

    context.dispatch('updateBudgetInDatabase', updateBudgets)

  },

  //END OF UPDATE DATA

  //DELETE DATA

  deleteDataFromAPIIfNeeded: (context, mutationType) => {
    if (mutationType.indexOf("Account") !== -1) {
      //Gets Data from Account If Needed
      context.dispatch('deleteDataForAccountIfNeeded')
    }
    else if (mutationType.indexOf("Transaction") !== -1) {
      //Gets Data from Income If Needed
      context.dispatch('deleteDataForTransactionIfNeeded')
    }
    else if (mutationType.indexOf("Item") !== -1) {
      //Gets Data from Item If Needed
      context.dispatch('deleteDataForItemIfNeeded')
    }

  },

  deleteDataForAccountIfNeeded: context => {
    let deleteAccounts = []

    for (let i = 0; i < context.state.Accounts.length; i++) {
      if (context.state.Accounts[i].objectState === "Delete") {
        deleteAccounts.push(context.state.Accounts[i])
      }
    }

    context.dispatch('deleteAccountinDatabase', deleteAccounts)

  },
  deleteDataForTransactionIfNeeded: context => {
    let deleteTransactions = []

      for (let i = 0; i < context.state.Transactions.length; i++) {
          if (context.state.Transactions[i].objectState === "Delete") {
              deleteTransactions.push(context.state.IncTransactionsomes[i])
      }
    }

    context.dispatch('deleteTransactionInDatabase', deleteTransactions)

  },
  deleteDataForItemIfNeeded: context => {
    let deleteItems = []

    for (let i = 0; i < context.state.Items.length; i++) {
      if (context.state.Items[i].objectState === "Delete") {
        deleteItems.push(context.state.Items[i])
      }
    }

    context.dispatch('deleteIteminDatabase', deleteItems)

  },

  //END OF DELETE DATA

  /////////////////// //////////////////////////////////////
  //End Of Actions For Updating API 
  /////////////////////////////////////////////////////////


  /////////////////////////////////////////////////////////
  //Category Actions    
  /////////////////////////////////////////////////////////

    getCategoryDataFromAPI: context => {
        let p1 = new Promise(
            // The resolver function is called with the ability to resolve or
            // reject the promise
            (resolve, reject) => {
                httpGet.getCategories(function (data) {
                    context.commit('updateStoreWithCategoryDataFromAPI', data)
                })
            });
    },
  addNewCategoryToDatabase: (context, categoryData) => {
    for (let i = 0; i < categoryData.length; i++) {
      let a1 = new Promise(
        // The resolver function is called with the ability to resolve or reject the promise
        (resolve, reject) => {
          httpPost.postNewCategory(categoryData[i], function (data) {
            for (let j = 0; j < context.state.Categories.length; j++) {
              if (context.state.Categories[j].categoryName === categoryData[i].categoryName) {
                context.state.Categories[j].id = data
                context.state.Categories[j].objectState = "Current"
              }
            }
          })
        });
    }

  },


  /////////////////////////////////////////////////////////
  //End Of Category Actions
  /////////////////////////////////////////////////////////


  /////////////////////////////////////////////////////////
  //Place Sold Actions    
  /////////////////////////////////////////////////////////

    getPlaceSoldDataFromAPI: context => {

        let p1 = new Promise(
            // The resolver function is called with the ability to resolve or
            // reject the promise
            (resolve, reject) => {
                httpGet.getPlaceSold(function (data) {
                    context.commit('updateStoreWithPlaceSoldDataFromAPI', data)
                })
            });
    },
    addNewPlaceSoldToDatabase: (context, placeSoldData) => {
        for (let i = 0; i < placeSoldData.length; i++) {
            let a1 = new Promise(
                // The resolver function is called with the ability to resolve or reject the promise
                (resolve, reject) => {
                    httpPost.postNewPlaceSold(placeSoldData[i], function (data) {
                        for (let j = 0; j < context.state.PlaceSold.length; j++) {
                            if (context.state.PlaceSold[j].RetailerName === placeSoldData[i].RetailerName) {
                                context.state.PlaceSold[j].id = data
                                context.state.PlaceSold[j].objectState = "Current"
                            }
                        }
                    })
                });
        }

    },


  /////////////////////////////////////////////////////////
  //End Of Place Sold Actions
  /////////////////////////////////////////////////////////


  /////////////////////////////////////////////////////////
  //Account Actions
  /////////////////////////////////////////////////////////

  getAccountDataFromAPI: context => {

    let p1 = new Promise(
      // The resolver function is called with the ability to resolve or
      // reject the promise
      (resolve, reject) => {
        httpGet.getAccounts(function (data) {
          context.commit('updateStoreWithAccountsDataFromAPI', data)
        })
      });
  },
  addNewAccountToDatabase: (context, accountData) => {
    //context.commit('addNewAccountToStore', accountData)

    for (let i = 0; i < accountData.length; i++) {
      let a1 = new Promise(
        // The resolver function is called with the ability to resolve or reject the promise
        (resolve, reject) => {
          httpPost.postNewAccount(accountData[i], function (data) {
            //for (let j = 0; j < context.state.Accounts.length; j++) {
              //if (context.state.Accounts[j].accountName === accountData[i].accountName) {
              accountData[i].id = data.data
              accountData[i].objectState = "Current"

              context.commit('updateStoreAfterPost', data)

             // }
           // }
          })
        });
    }
    
  },
  updateAccountinDatabase: (context, accountData) => {
    //context.commit('updateAccountinStore', accountData)

    for (let i = 0; i < accountData.length; i++) {
      let u1 = new Promise(
        // The resolver function is called with the ability to resolve or reject the promise
        (resolve, reject) => {
          httpPut.updateAccount(accountData[i], function (data) {
            if (data === 200) {
              for (let j = 0; j < context.state.Accounts.length; j++) {
                if (context.state.Accounts[j].id === accountData[i].id) {
                  //context.state.Accounts[j].id = data
                  context.state.Accounts[j].objectState = "Current"
                }
              }
            }
          })
        });
    }

  },
  deleteAccountinDatabase: (context, accountData) => {
    //context.commit('updateAccountinStore', accountData)

    for (let i = 0; i < accountData.length; i++) {
      let d1 = new Promise(
        // The resolver function is called with the ability to resolve or
        // reject the promise
        (resolve, reject) => {
          httpDelete.deleteAccount(accountData[i], function (data) {
            if (data === 200) {
              context.commit('deleteAccountfromStore', accountData[i])
            }
          })
        });
    }
  },

  /////////////////////////////////////////////////////////
  //End Of Account Actions
  /////////////////////////////////////////////////////////


  /////////////////////////////////////////////////////////
  //Transaction Actions
  /////////////////////////////////////////////////////////

  getTransactionDataFromAPI: context => {
    let p1 = new Promise(
      // The resolver function is called with the ability to resolve or
      // reject the promise
      (resolve, reject) => {
        httpGet.getTransactions(function (data) {
            context.commit('updateStoreWithTransactionDataFromAPI', data)
        })
      });
  },
  addNewTransactionToDatabase: (context, transactionData) => {
    //context.commit('addNewIncomeToStore', transactionData)

    //for (let i = 0; i < transactionData.length; i++) {
      let a1 = new Promise(
        // The resolver function is called with the ability to resolve or
        // reject the promise
        (resolve, reject) => {
              httpPost.postNewTransaction(transactionData, function (data) {
                  for (let i = 0; i < data.length; i++) {
                      for (let j = 0; j < context.state.Transactions.length; j++) {
                          if (context.state.Transactions[j].transactionName === data[i].transactionName) {
                              context.state.Transactions[j].id = data[i].id
                              context.state.Transactions[j].objectState = "Current"
                          }
                      }
                  }
          })
        });
    //}
  },  
  updateTransactionInDatabase: (context, transactionData) => {
    //context.commit('updateIncomeinStore', transactionData)

    for (let i = 0; i < transactionData.length; i++) {
      let u1 = new Promise(
        // The resolver function is called with the ability to resolve or
        // reject the promise
        (resolve, reject) => {
          httpPut.updateTransaction(transactionData[i], function (data) {
            if (data === 200) {
                for (let j = 0; j < context.state.Transactions.length; j++) {
                  if (context.state.Transactions[j].id === transactionData[i].id) {
                    context.state.Transactions[j].objectState = "Current"
                }
              }
            }
          })
        });
    }

  },
  deleteTransactionInDatabase: (context, transactionData) => {
    //context.commit('updateIncomeinStore', transactionData)

    for (let i = 0; i < transactionData.length; i++) {
      let d1 = new Promise(
        // The resolver function is called with the ability to resolve or
        // reject the promise
        (resolve, reject) => {
          httpDelete.deleteTransaction(transactionData[i], function (data) {
            if (data === 200) {
              context.commit('deleteTransactionfromStore', transactionData)
            }
          })
        });
    }
  },

  /////////////////////////////////////////////////////////
  //End Of Transaction Actions
  /////////////////////////////////////////////////////////


  /////////////////////////////////////////////////////////
  //Item Actions
  /////////////////////////////////////////////////////////

  getItemDataFromAPI: (context, query) => {
    let p1 = new Promise(
      // The resolver function is called with the ability to resolve or
      // reject the promise
      (resolve, reject) => {
        httpGet.getItems(query, function (data) {
          context.commit('updateStoreWithItemDataFromAPI', data)
        })
      });
  },
  addNewItemToDatabase: (context, itemData) => {
    //context.commit('addNewItemToStore', itemData)

      itemData = itemManager.formatAllAsItem(itemData)

    //for (let i = 0; i < itemData.length; i++) {
      let a1 = new Promise(
        // The resolver function is called with the ability to resolve or
        // reject the promise
        (resolve, reject) => {
            httpPost.postNewItem(itemData, function (data) {
                for (let i = 0; i < data.length; i++) {
                    for (let j = 0; j < context.state.Items.length; j++) {
                        if (context.state.Items[j].itemName === data[i].itemName) {
                            context.state.Items[j].id = data[i].id
                            context.state.Items[j].objectState = "Current"
                        }
                    }
                }
            })
        });
    //}
  },
  updateItemInDatabase: (context, itemData) => {
    //context.commit('updateIteminStore', itemData)

    for (let i = 0; i < itemData.length; i++) {
      let u1 = new Promise(
        // The resolver function is called with the ability to resolve or
        // reject the promise
        (resolve, reject) => {
          httpPut.updateItem(itemData, function (data) {
            if (data === 200) {
              for (let j = 0; j < context.state.Items.length; j++) {
                if (context.state.Items[j].id === itemData[i].id) {
                  context.state.Items[j].objectState = "Current"
                }
              }
            }
          })
        });
    }

  },
  deleteIteminDatabase: (context, itemData) => {

    for (let i = 0; i < itemData.length; i++) {
      let d1 = new Promise(
        // The resolver function is called with the ability to resolve or
        // reject the promise
        (resolve, reject) => {
          httpDelete.deleteItem(itemData, function (data) {
            if (data === 200) {
              context.commit('deleteItemfromStore', itemData)
            }
          })
        });
    }
  },

  /////////////////////////////////////////////////////////
  //End Of Item Actions
  /////////////////////////////////////////////////////////


  /////////////////////////////////////////////////////////
  //Budget Actions
  /////////////////////////////////////////////////////////

  getBudgetDataFromAPI: context => {
    let p1 = new Promise(
      // The resolver function is called with the ability to resolve or
      // reject the promise
      (resolve, reject) => {
        httpGet.getBudgets(function (data) {
          context.commit('updateStoreWithBudgetDataFromAPI', data)
        })
      });
  },
  addNewBudgetToDatabase: (context, budgetData) => {

      budgetData = formatAllAsBudget(budgetData)

    //for (let i = 0; i < budgetData.length; i++) {
      let a1 = new Promise(
        // The resolver function is called with the ability to resolve or
        // reject the promise
        (resolve, reject) => {
          httpPost.postNewBudget(budgetData, function (data) {
            for (let j = 0; j < context.state.Budgets.length; j++) {
              if (context.state.Budgets[j].budgetName === budgetData[i].budgetName) {
                context.state.Budgets[j].id = data
                context.state.Budgets[j].objectState = "Current"
              }
            }
          })
        });
    //}
  },
  updateBudgetInDatabase: (context, budgetData) => {
   
    for (let i = 0; i < budgetData.length; i++) {
      let u1 = new Promise(
        // The resolver function is called with the ability to resolve or
        // reject the promise
        (resolve, reject) => {
          httpPut.updateBudget(budgetData[i], function (data) {
            if (data === 200) {
              for (let j = 0; j < context.state.Budgets.length; j++) {
                if (context.state.Budgets[j].id === budgetData[i].id) {
                  context.state.Budgets[j].objectState = "Current"
                }
              }
            }
          })
        });
    }

  },
  deleteBudgetinDatabase: (context, budgetData) => {

    for (let i = 0; i < budgetData.length; i++) {
      let d1 = new Promise(
        // The resolver function is called with the ability to resolve or
        // reject the promise
        (resolve, reject) => {
          httpDelete.deleteBudget(budgetData[i], function (data) {
            if (data === 200) {
              context.commit('deleteBudgetfromStore', budgetData)
            }
          })
        });
    }
  },

  /////////////////////////////////////////////////////////
  //End Of Budget Actions
  /////////////////////////////////////////////////////////


  /////////////////////////////////////////////////////////
  //Currency Actions
  /////////////////////////////////////////////////////////

  getCurrencyDataFromAPI: context => {
    let p1 = new Promise(
      // The resolver function is called with the ability to resolve or
      // reject the promise
      (resolve, reject) => {
        httpGet.getCurrencies(function (data) {
          context.commit('updateStoreWithCurrencyDataFromAPI', data)
        })
      });
  },
  addNewCurrencyToDatabase: (context, currencyData) => {

    for (let i = 0; i < currencyData.length; i++) {
      let a1 = new Promise(
        // The resolver function is called with the ability to resolve or
        // reject the promise
        (resolve, reject) => {
          httpPost.postNewCurrency(currencyData[i], function (data) {
            for (let j = 0; j < context.state.Currencies.length; j++) {
              if (context.state.Currencies[j].currencyName === currencyData[i].currencyName) {
                context.state.Currencies[j].id = data
                context.state.Currencies[j].objectState = "Current"
              }
            }
          })
        });
    }
  },
  updateCurrencyInDatabase: (context, currencyData) => {

    for (let i = 0; i < currencyData.length; i++) {
      let u1 = new Promise(
        // The resolver function is called with the ability to resolve or
        // reject the promise
        (resolve, reject) => {
          httpPut.updateCurrency(currencyData[i], function (data) {
            if (data === 200) {
              for (let j = 0; j < context.state.Currencies.length; j++) {
                if (context.state.Currencies[j].id === currencyData[i].id) {
                  context.state.Currencies[j].objectState = "Current"
                }
              }
            }
          })
        });
    }

  },
  deleteCurrencyinDatabase: (context, currencyData) => {

    for (let i = 0; i < currencyData.length; i++) {
      let d1 = new Promise(
        // The resolver function is called with the ability to resolve or
        // reject the promise
        (resolve, reject) => {
          httpDelete.deleteCurrency(currencyData[i], function (data) {
            if (data === 200) {
              context.commit('deleteCurrencyfromStore', currencyData)
            }
          })
        });
    }
  },

  /////////////////////////////////////////////////////////
  //End Of Currency Actions
  /////////////////////////////////////////////////////////


  /////////////////////////////////////////////////////////
  //Audit Actions
  /////////////////////////////////////////////////////////

    getAuditDataFromAPI: context => {
        let p1 = new Promise(
        // The resolver function is called with the ability to resolve or
        // reject the promise
        (resolve, reject) => {
             httpGet.getAudit(function (data) {
                 //context.commit('updateStoreWithCurrencyDataFromAPI', data)
                 return data
            })
        });
    },

  /////////////////////////////////////////////////////////
  //End Of Audit Actions
  /////////////////////////////////////////////////////////


  /////////////////////////////////////////////////////////
  //User Actions
  /////////////////////////////////////////////////////////

  getUserDataFromAPI: (context, userId) => {
    let p1 = new Promise(
      // The resolver function is called with the ability to resolve or
      // reject the promise
      (resolve, reject) => {
        httpGet.getUser(function (data) {
          context.commit('updateStoreWithUserDataFromAPI', data)
        })
      });
  },


  /////////////////////////////////////////////////////////
  //End Of User Actions
  /////////////////////////////////////////////////////////


    /////////////////////////////////////////////////////////
    //User Actions
    /////////////////////////////////////////////////////////

    getPeriodDataFromAPI: context => {
        let p1 = new Promise(
            // The resolver function is called with the ability to resolve or
            // reject the promise
            (resolve, reject) => {
                httpGet.getPeriod(function (data) {
                    context.commit('updateSelectPeriodInStore', data)
                })
            });
    },


  /////////////////////////////////////////////////////////
  //End Of User Actions
  /////////////////////////////////////////////////////////

    getRefreshToken: (context, tokens) => {
        return new Promise((resolve, reject) => {
            httpPost.getNewToken(tokens, function (data) {
                if (globalFunctions.isNullOrEmpty(data.refreshToken) || globalFunctions.isNullOrEmpty(data.tokens)) { 
                    auth.login(data.refreshToken, data.token)
                }
            })
        });
    }




}
