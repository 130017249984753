import { HTTP } from './httpCommon';

export const httpPost = {

  ////////////////////////////////////////////
  ///ACCOUNTS
  ////////////////////////////////////////////

  //Post Account
  postNewAccount(account, callback) {
    HTTP.post('Accounts',
      account
      )
      .then(response => {
        callback(response)
      })
      .catch(e => {
        //this.errors.push(e)
        console.log(e);
      })
      
  },


  ////////////////////////////////////////////
  ///TRANSACTION
  ////////////////////////////////////////////

  //Post Transaction
  postNewTransaction(transaction, callback) {
      HTTP.post('Transactions',
          transaction
    )
      .then(response => {
        callback(response)
      })
      .catch(e => {
        //this.errors.push(e)
        console.log(e);
      })
  },


  ////////////////////////////////////////////
  ///ITEM
  ////////////////////////////////////////////

  //Post Item
  postNewItem(item, callback) {
    HTTP.post('Items',
      item
    )
      .then(response => {
        callback(response.data)
      })
      .catch(e => {
        //this.errors.push(e)
        console.log(e);
      })
  },


    ////////////////////////////////////////////
    ///PLACESOLD
    ////////////////////////////////////////////

    //Post Item
    postNewPlaceSold(placeSold, callback) {
        HTTP.post('PlaceSold',
            placeSold
        )
            .then(response => {
                callback(response.status)
            })
            .catch(e => {
                //this.errors.push(e)
                console.log(e);
            })
    },


  ////////////////////////////////////////////
  ///BUDGET
  ////////////////////////////////////////////

  //Post Budget
  postNewBudget(budget, callback) {
    HTTP.post('Budgets',
      budget
    )
      .then(response => { 
        callback(response.status)
      })
      .catch(e => {
        //this.errors.push(e)
        console.log(e);
      })
  },


  ////////////////////////////////////////////
  ///Currency
  ////////////////////////////////////////////

  //Post Currency
  postNewCurrency(currency, callback) {
    HTTP.post('Currencies',
      currency
    )
      .then(response => {
        callback(response.status)
      })
      .catch(e => {
        //this.errors.push(e)
        console.log(e);
      })
  },

  ////////////////////////////////////////////
  ///CATEGORIES
  ////////////////////////////////////////////

  //Post Category
  postNewCategory(category, callback) {
    HTTP.post('Categories',
      category
    )
      .then(response => {
        callback(response.status)
      })
      .catch(e => {
        console.log(e);
      })
  },

    ////////////////////////////////////////////
    ///AUDIT
    ////////////////////////////////////////////

    //Post Audit
    postNewAudit(audit, callback) {
        HTTP.post('Audit',
            audit
        )
            .then(response => {
                callback(response.status)
            })
            .catch(e => {
                console.log(e);
            })
    },


    ///////////////////////////////////////////////
    ///Refresh Token
    ///////////////////////////////////////////////

    //Get Refresh Token
    getNewToken(tokens, callback) {
        HTTP.post('AuthManagement/RefreshToken',
            tokens
        )
            .then(response => {
                callback(response)
            })
            .catch(e => {
                callback(e)
            })
    }


}


