import { globalFunctions } from './globalFunctions'

export const itemManager = {
    itemObject: {
        id: 0,
        itemName: '',
        currency: '',
        category: '',
        isTaxed: false,
        gct: 0,
        placeSold: '',
        placeSoldLocation: '',
        priceList: [],
        dateChanged: '',
        objectState: 'New'
    },
    //////////////////////////////////////////////////
    //Convert To Item Format
    /////////////////////////////////////////////////

    formatAsItem(item) {
        this.itemObject.id = item.id
        this.itemObject.itemName = item.itemName
        this.itemObject.category = item.category
        this.itemObject.currency = item.currency
        this.itemObject.isTaxed = item.isTaxed

        if (item.gct > 0) {
            this.itemObject.gct = parseFloat(item.gct)
        }

        this.itemObject.placeSold = item.placeSold
        this.itemObject.placeSoldLocation = item.placeSoldLocation
        this.itemObject.priceList = item.priceList
        this.itemObject.dateChanged = item.dateChanged
        this.itemObject.objectState = item.objectState

        return this.itemObject
    },

    /////////////////////////////////////////////////
    //Convert All to Item Format
    ////////////////////////////////////////////////

    formatAllAsItem(items) {
        let newItems = []

        for (let i = 0; i < items.length; i++) {
            newItems.push(globalFunctions.stringify(this.formatAsItem(items[i])))
        }

        return newItems
    }


}