import Vue from 'vue'
import VueRouter from 'vue-router'
import { auth } from '@/auth/index'
import HomePage from '@/views/HomePage'

Vue.use(VueRouter)

let requiresAuth = true

const routes = [
    { path: '/', component: HomePage },
    {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (login.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "login" */ '../views/userAccount/login.vue')
    },
    {
        path: '/registration',name: 'registration',
        component: () => import(/* webpackChunkName: "registration" */ '../views/userAccount/registration.vue')
    },
    {
        path: '/passwordRecovery', name: 'passwordRecovery',
        component: () => import(/* webpackChunkName: "passwordRecovery" */ '../views/userAccount/passwordRecovery.vue')
    },
    {
        path: '/passwordRecoveryChange/:itemId', name: 'passwordRecoveryChange', meta: { requiresAuth },
        component: () => import(/* webpackChunkName: "passwordRecovery" */ '../views/userAccount/passwordRecoveryChange.vue')
    },

    //Dashboard Links
    {
        path: '/dashboard/', meta: { requiresAuth },
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/dashboard/dashboard.vue'),
        children:
            [
                // DashboardHome will be rendered inside Dashboard's <router-view>
                // when /Dashboard is matched
                { path: '', redirect: 'home' },
                {
                    path: 'home', name: 'dashboardHome', meta: { requiresAuth },
                    component: () => import(/* webpackChunkName: "dashboardHome" */ '../views/dashboard/dashboardHome.vue')
                },

                //Widget See More
                {
                    path: 'widgetSeeMoreContainer', name: 'widgetSeeMoreContainer', meta: { requiresAuth },
                    component: () => import(/* webpackChunkName: "WidgetSeeMore" */ '../views/dashboard/widgetSeeMoreContainer.vue')
                },

                //Profile Page
                {
                    path: 'profile', name: 'profile', meta: { requiresAuth },
                    component: () => import(/* webpackChunkName: "profile" */ '../views/userAccount/profile.vue')
                },
                {
                    path: 'changePassword', name: 'changePassword', meta: { requiresAuth },
                    component: () => import(/* webpackChunkName: "changePassword" */ '../views/userAccount/changePassword.vue')
                },
                {
                    path: 'passwordChanged/:itemId', name: 'passwordChanged',
                    component: () => import(/* webpackChunkName: "passwordChanged" */ '../views/userAccount/passwordChanged.vue')
                },

                //navigation Pages
                {
                    path: 'budgetData', name: 'budgetData', meta: { requiresAuth },
                    component: () => import(/* webpackChunkName: "budgetData" */ '../views/dashboard/navigationPages/budgetData.vue')
                },
                {
                    path: 'extensions', name: 'extensions', meta: { requiresAuth },
                    component: () => import(/* webpackChunkName: "extensions" */ '../views/dashboard/navigationPages/extensions.vue')
                },
                {
                    path: 'mainNav', name: 'mainNav', meta: { requiresAuth },
                    component: () => import(/* webpackChunkName: "mainNav" */ '../views/dashboard/navigationPages/mainNav.vue')
                },
                {
                    path: 'reminders', name: 'reminders', meta: { requiresAuth }, 
                    component: () => import(/* webpackChunkName: "reminders" */ '../views/dashboard/navigationPages/reminders.vue')
                },

                //Account Link
                {
                    path: 'account', name: 'viewAccounts', meta: { requiresAuth },
                    component: () => import(/* webpackChunkName: "viewAccounts" */ '../views/account/viewAccounts.vue')
                },
                {
                    path: 'addAccount', name: 'addAccount', meta: { requiresAuth },
                    component: () => import(/* webpackChunkName: "addAccount" */ '../views/account/addAccounts.vue')
                },
                {
                    path: 'editAccount/:itemId', name: 'editAccount', meta: { requiresAuth },
                    component: () => import(/* webpackChunkName: "editAccount" */ '../views/account/editAccounts.vue')
                },


                //Budget Link
                {
                    path: 'budget', name:'viewBudgets' , meta: { requiresAuth },
                    component: () => import(/* webpackChunkName: "viewBudgets" */ '../views/budget/viewBudgets.vue')
                },
                {
                    path: 'addBudget', name: 'addBudget', meta: { requiresAuth },
                    component: () => import(/* webpackChunkName: "addBudget" */ '../views/budget/addBudgets.vue')
                },
                {
                    path: 'editBudget/:itemId', name:'editBudget', meta: { requiresAuth },
                    component: () => import(/* webpackChunkName: "editBudget" */ '../views/budget/editBudgets.vue')
                },

                //Income Link
                {
                    path: 'income', name:'viewIncomes', meta: { requiresAuth },
                    component: () => import(/* webpackChunkName: "viewIncomes" */ '../views/income/viewIncomes.vue')
                },
                //{
                //    path: 'addIncome', name: 'addIncome', meta: { requiresAuth },
                //    component: () => import(/* webpackChunkName: "addIncome" */ '../views/income/addIncomes.vue')
                //},
                //{
                //    path: 'EditIncome/:itemId', name: 'editIncome', meta: { requiresAuth },
                //    component: () => import(/* webpackChunkName: "editIncome" */ '../views/income/editIncomes.vue')
                //},

                //Expense Link
                {
                    path: 'expense', name: 'viewExpense', meta: { requiresAuth },
                    component: () => import(/* webpackChunkName: "viewExpense" */ '../views/expense/viewExpenses.vue')
                },
                //{
                //    path: 'addExpense', name: 'addExpense', meta: { requiresAuth },
                //    component: () => import(/* webpackChunkName: "addExpense" */ '../views/expense/addExpenses.vue')
                //},
                //{
                //    path: 'editExpense/:itemId', name: 'editExpense', meta: { requiresAuth },
                //    component: () => import(/* webpackChunkName: "editExpense" */ '../views/expense/editExpenses.vue')
                //},

                //Transaction Link
                {
                    path: 'transaction', name: 'viewTransaction', meta: { requiresAuth },
                    component: () => import(/* webpackChunkName: "viewTransaction" */ '../views/transaction/viewTransactions.vue')
                },
                {
                    path: 'addTransaction/:itemId', name: 'addTransaction', meta: { requiresAuth },
                    component: () => import(/* webpackChunkName: "addTransaction" */ '../views/transaction/addTransaction.vue')
                },
                {
                    path: 'editTransaction/:itemId', name: 'editTransaction', meta: { requiresAuth },
                    component: () => import(/* webpackChunkName: "editTransaction" */ '../views/transaction/editTransaction.vue')
                },

                //Transfer Link
                {
                    path: 'transfer', name: 'viewTransfer', meta: { requiresAuth },
                    component: () => import(/* webpackChunkName: "viewTransfer" */ '../views/transfer/viewTransfers.vue')
                },
                //{
                //    path: 'addTransfer', name: 'addTransfer', meta: { requiresAuth },
                //    component: () => import(/* webpackChunkName: "addTransfer" */ '../views/transfer/addTransfers.vue')
                //},
                //{
                //    path: 'editTransfer/:itemId', name: 'editTransfer', meta: { requiresAuth },
                //    component: () => import(/* webpackChunkName: "editTransfer" */ '../views/transfer/editTransfers.vue')
                //},

                //Category Links
                {
                    path: 'category', name: 'viewCategory', meta: { requiresAuth },
                    component: () => import(/* webpackChunkName: "viewCategory" */ '../views/administrator/category/viewCategory.vue')
                },
                {
                    path: 'addCategory', name: 'addCategory', meta: { requiresAuth },
                    component: () => import(/* webpackChunkName: "addCategory" */ '../views/administrator/category/addCategory.vue')
                },
                {
                    path: 'editCategory/:itemId', name: 'editCategory', meta: { requiresAuth },
                    component: () => import(/* webpackChunkName: "editCategory" */ '../views/administrator/category/editCategory.vue')
                },

                //Help Link
                {
                    path: 'help', name: 'help',
                    component: () => import(/* webpackChunkName: "help" */ '../views/help/help.vue')
                },

                //Feedback Link
                {
                    path: 'suggestions', name: 'suggestions',
                    component: () => import(/* webpackChunkName: "suggestions" */ '../views/feedback/suggestions.vue')
                },
                {
                    path: 'issues', name: 'issues',
                    component: () => import(/* webpackChunkName: "issues" */ '../views/feedback/issues.vue')
                },

                //Settings Page
                {
                    path: 'settings', name: 'settings', meta: { requiresAuth },
                    component: () => import(/* webpackChunkName: "settings" */ '../views/settings/settings.vue')
                },

                //Notifications Page
                {
                    path: 'notifications', name: 'notifications', meta: { requiresAuth },
                    component: () => import(/* webpackChunkName: "notifications" */ '../views/notification/notifications.vue')
                },
                {
                    path: 'notification/:itemId', name: 'notificationSingle', meta: { requiresAuth },
                    component: () => import(/* webpackChunkName: "notificationSingle" */ '../views/notification/notificationSingle.vue')
                },

                //Error Page Link
                {
                    path: "*", name: 'derror',
                    component: () => import(/* webpackChunkName: "error" */ '../views/error.vue')
                }
            ]
    },
    //Error Page Link
    {
        path: "*", name:'error',
        component: () => import(/* webpackChunkName: "error" */ '../views/error.vue')
    }
]

let router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
    if (to.meta.requiresAuth) {
        if (auth.checkAuth()) {
            next()
        }
        else {
            next({ path: '/login' })
        }
    }
    else {
        next()
    }
})

export default router
