import { HTTP } from './httpCommon';

export const httpDelete = {

  ////////////////////////////////////////////
  ///ACCOUNTS
  ////////////////////////////////////////////

  //Delete Account
  deleteAccount(account, callback) {
    HTTP.delete('Accounts/'+
        account.id 
    )
      .then(response => {
        callback(response.status)
      })
      .catch(e => {
        //this.errors.push(e)
        console.log(e);
      })

  },


  ////////////////////////////////////////////
  ///TRANSACTIONS
  ////////////////////////////////////////////

  //Delete Transaction
  deleteIncome(transaction, callback) {
    HTTP.delete('Transactions/' +
        transaction.id
    )
      .then(response => {
        callback(response.status)
      })
      .catch(e => {
        //this.errors.push(e)
        console.log(e);
      })

  },


  ////////////////////////////////////////////
  ///ITEMS
  ////////////////////////////////////////////

  //Delete Item
  deleteItem(item, callback) {
    HTTP.delete('Items/' +
      item.id
    )
      .then(response => {
        callback(response.status)
      })
      .catch(e => {
        //this.errors.push(e)
        console.log(e);
      })

  },


  ////////////////////////////////////////////
  ///BUDGETS
  ////////////////////////////////////////////

  //Delete Budget
  deleteBudget(budget, callback) {
    HTTP.delete('Budgets/' +
      budget.id
    )
      .then(response => {
        callback(response.status)
      })
      .catch(e => {
        //this.errors.push(e)
        console.log(e);
      })

  },


  ////////////////////////////////////////////
  ///Curriences
  ////////////////////////////////////////////

  //Delete Currency
  deleteCurrency(currency, callback) {
    HTTP.delete('Curriencies/' +
      currency.id
    )
      .then(response => {
        callback(response.status)
      })
      .catch(e => {
        //this.errors.push(e)
        console.log(e);
      })

  },


}
