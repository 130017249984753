<template>
    <div>
        <router-view></router-view>
    </div>
</template>

<script>
    import 'vuetify/dist/vuetify.min.css'
    import './styles/mainstyles.min.css'


    export default {
    }

</script>

<style>
    .fade-enter-active,
    .fade-leave-active {
        transition-duration: 0.3s;
        transition-property: opacity;
        transition-timing-function: ease;
    }

    .fade-enter,
    .fade-leave-active {
        opacity: 0
    }
</style>

