
export const auth = {

  // User object will let us check authentication status
  user: {
    authenticated: false
  },

  // Send a request to the login URL and save the returned JWT
  login(refresh_token, access_token)
  {
      localStorage.setItem('access_token', access_token)

      //localStorage.setItem('login', true)

      this.user.authenticated = true
  },


  // To log out, we just need to remove the token
  logout()
  {
      //localStorage.removeItem('refresh_token')
      localStorage.removeItem('access_token')
      localStorage.removeItem('store')
      this.user.authenticated = false
  },

  checkAuth()
  {
    var jwt = localStorage.getItem('access_token')
    if (jwt) {
      this.user.authenticated = true
      return true
    }
    else {
      this.user.authenticated = false
      return false
    }
  },

  //The object to be passed as a header for authenticated requests
  getAuthHeader()
  {
    var header = 'Bearer ' + localStorage.getItem('access_token')
    return {
      header
    }
    },

  getAccessToken() {
      return localStorage.getItem('access_token')
  } 
}




