import { globalFunctions } from './globalFunctions'

export const dateManager = {

    returnDateRange(date) {

        let newDate = {
            startDate: '',
            endDate: ''
        }

        date = this.reformatDateFromApi(date)

        let dates = date.split(":")

        if (globalFunctions.isNullOrEmpty(dates[1])) {
            let dateSplit = date.split("-")

            if (globalFunctions.isNullOrEmpty(dateSplit[1])) {
                newDate.startDate = dateSplit[0] + "-01-01"
                newDate.endDate = dateSplit[0] + "-12-31"
            }
            else if (globalFunctions.isNullOrEmpty(dateSplit[2])) {
                newDate.startDate  = dateSplit[0] + "-" + dateSplit[1] + "-01"
                newDate.endDate = dateSplit[0] + "-" + dateSplit[1] + "-" + this.daysInMonth(dateSplit[0], dateSplit[1])
            }
            else {
                newDate.startDate  = dateSplit[0] + "-" + dateSplit[1] + "-" + dateSplit[2]
                newDate.endDate = dateSplit[0] + "-" + dateSplit[1] + "-" + dateSplit[2]
            }
        } else {
            ////////////////////////////////////////
            ///TODO:
            ////////////////////////////////////////
        }

        return newDate
    },
    reformatDateFromApi(date) {
        if (date.indexOf(' ') !== -1) {
            let newDate = date.split(' ')

            if (newDate[0].indexOf('/') !== -1) {
                let nnewDate = newDate[0].split('/')

                if (nnewDate[0] < 10) {
                    nnewDate[0] = "0" + nnewDate[0]
                }

                if (nnewDate[1] < 10) {
                    nnewDate[1] = "0" + nnewDate[1]
                }

                //Changes from Month/Day/Year to Year-Month-Day
                //Format is Year-Month-Day
                return nnewDate[2] + "-" + nnewDate[0] + "-" + nnewDate[1]  
            }
        }

        return date
    },
    returnFormattedDate(date) {
        date = this.reformatDateFromApi(date)

        let dateSplit = date.split("-")

        return new Date(dateSplit[0], dateSplit[1], dateSplit[2])
    },
    isWithinDateRange(start, end, date) {
        let newStart = this.returnFormattedDate(this.returnDateLessOneMonth(start))
        let newEnd = this.returnFormattedDate(this.returnDateLessOneMonth(end))
        let newDate = this.returnFormattedDate(this.returnDateLessOneMonth(date))

        if (newDate >= newStart && newDate <= newEnd) {
            return true
        }
        return false
    },
    returnDateLessOneMonth(date) {
        date = this.reformatDateFromApi(date)

        let dateSplit = date.split("-")

        if (globalFunctions.isNullOrEmpty(dateSplit[1])) {
            return this.monthMinusOne(date)
        }

        return (dateSplit[0] + "-" + this.monthMinusOne(dateSplit[1]) + "-" + dateSplit[2])
    },
    monthMinusOne(oldMonth) {
        let month = parseInt(oldMonth) - 1

        return month > 9 ? month : "0" + month.toString()
    },
    daysInMonth(month, year) {
        return new Date(year, this.returnDateLessOneMonth(month), 0).getDate();
    },
    nextDate(date) {
        let dateSplit = date.split("-")

        if (globalFunctions.isNullOrEmpty(dateSplit[1])) {
            return (parseInt(dateSplit[0]) + 1)
        }
        else if (globalFunctions.isNullOrEmpty(dateSplit[2])) {
            return (dateSplit[0] + "-" + (parseInt(dateSplit[1]) + 1))
        }
        else {
            return (dateSplit[0] + "-" + dateSplit[1] + "-" + (parseInt(dateSplit[2]) + 1))
        }
    },
    previousDate(date) {
        let dateSplit = date.split("-")

        if (globalFunctions.isNullOrEmpty(dateSplit[1])) {
            return (parseInt(dateSplit[0]) - 1)
        }
        else if (globalFunctions.isNullOrEmpty(dateSplit[2])) {
            return (dateSplit[0] + "-" + (parseInt(dateSplit[1]) - 1))
        }
        else {
            return (dateSplit[0] + "-" + dateSplit[1] + "-" + (parseInt(dateSplit[2]) - 1))
        }
    },
    returnPreviousPeriodRange(settings, period) {
        let periodStart = ''

        let dateSplit = period.split(" - ")
        periodStart = new Date(dateSplit[0])

        switch (settings.period) {
            case "Weekly":
                return this.buildPreviousPeriodRangeForWeek(periodStart)
            case "Bi-Weekly":
                return this.buildPreviousPeriodRangForBiWeekly(periodStart)
            case "Monthly":
                return this.buildPreviousPeriodRangeForMonth(periodStart)
            case "Yearly":
                return this.buildPreviousPeriodRangeForYear(periodStart)
        }
    },
    returnNextPeriodRange(settings, period) {
        let periodStart = ''

        let dateSplit = period.split(" - ")
        periodStart = new Date(dateSplit[0])

        switch (settings.period) {
            case "Weekly":
                return this.buildNextPeriodRangeForWeek(periodStart)
            case "Bi-Weekly":
                return this.buildNextPeriodRangForBiWeekly(periodStart)
            case "Monthly":
                return this.buildNextPeriodRangeForMonth(periodStart)
            case "Yearly":
                return this.buildNextPeriodRangeForYear(periodStart)
        }
    },
    returnPeriodRange(settings, date) {
        switch (settings.period) {
            case "Weekly":
                return this.buildPeriodRangeForWeek(settings.periodStartDayOfWeek, date)
            case "Bi-Weekly":
                return this.buildPeriodRangForBiWeekly(settings.periodStartDayOfWeek, settings.periodStartDate, date)
            case "Monthly":
                return this.buildPeriodRangeForMonth(settings.periodStartDay, date)
            case "Yearly":
                return this.buildPeriodRangeForYear(settings.periodStartDate, date)
        }
    },
    buildPreviousPeriodRangeForWeek(date) {
        let start = ''

        start = date.setDate(date.getDate() - 7);

        return this.formatDateForPeriod(start) + " - " + this.formatDateForPeriod(this.returnEndDateForPeriodWeek(start))
    },
    buildNextPeriodRangeForWeek(date) {
        let start = ''

        start = date.setDate(date.getDate() + 7);

        return this.formatDateForPeriod(start) + " - " + this.formatDateForPeriod(this.returnEndDateForPeriodWeek(start))
    },
    buildPeriodRangeForWeek(startDay, date) {
        let start = ''

        start = this.returnStartDateForPeriodWeek(startDay, date)

        return this.formatDateForPeriod(start) + " - " + this.formatDateForPeriod(this.returnEndDateForPeriodWeek(start))
    },
    buildPreviousPeriodRangForBiWeekly(date) {
        let currentStart = ''

        currentStart = date.setDate(date.getDate() - 14);

        return this.formatDateForPeriod(currentStart) + " - " + this.formatDateForPeriod(this.returnEndDateForPeriodBiWeek(currentStart))

    },
    buildNextPeriodRangForBiWeekly(date) {
        let currentStart = ''

        currentStart = date.setDate(date.getDate() + 14);

        return this.formatDateForPeriod(currentStart) + " - " + this.formatDateForPeriod(this.returnEndDateForPeriodBiWeek(currentStart))

    },
    buildPeriodRangForBiWeekly(startDay, StartDate, currentDate) {
        let oldStart = ''
        let currentStart = ''

        oldStart = this.returnStartDateForPeriodWeek(startDay, StartDate)

        currentStart = this.returnCurrentStartDateForBiWeekly(oldStart, currentDate)

        return this.formatDateForPeriod(currentStart) + " - " + this.formatDateForPeriod(this.returnEndDateForPeriodBiWeek(currentStart))

    },
    buildPreviousPeriodRangeForMonth(date) {
        let startDate = ''
        let endDate = ''

        startDate = new Date(date.getMonth() + "-" + date.getDate() + "-" + date.getFullYear())
        
        let end = new Date(startDate)
        end.setMonth(end.getMonth() + 1)
        endDate = new Date(end + "-" + startDate.getDate() + "-" + startDate.getFullYear())
        endDate.setDate(endDate.getDate() - 1)

        return this.formatDateForPeriod(startDate) + " - " + this.formatDateForPeriod(endDate)
    },
    buildNextPeriodRangeForMonth(date) {
        let startDate = ''
        let endDate = ''

        startDate = new Date((date.getMonth() + 2) + "-" + date.getDate() + "-" + date.getFullYear())

        let end = new Date(startDate)
        end.setMonth(end.getMonth() + 1)
        endDate = new Date(end + "-" + startDate.getDate() + "-" + startDate.getFullYear())
        endDate.setDate(endDate.getDate() - 1)

        return this.formatDateForPeriod(startDate) + " - " + this.formatDateForPeriod(endDate)
    },
    buildPeriodRangeForMonth(startDay, date) {
        let currentDay = date.getDate()
        let startDate = ''
        let endDate = ''

        if (currentDay < parseInt(startDay)) {
            startDate = new Date(date.getMonth() + "-" + startDay + "-" + date.getFullYear())
        }
        else {
            startDate = new Date((date.getMonth() + 1) + "-" + startDay + "-" + date.getFullYear())
        }
        let end = new Date(startDate)
        end.setMonth(end.getMonth() + 1)
        endDate = new Date(end + "-" + startDate.getDate() + "-" + startDate.getFullYear())
        endDate.setDate(endDate.getDate() - 1)

        return this.formatDateForPeriod(startDate) + " - " + this.formatDateForPeriod(endDate)
    },
    buildPreviousPeriodRangeForYear(date) {
        let startDate = ''
        let endDate = ''

        startDate = new Date((date.getMonth() + 1) + "-" + date.getDate() + "-" + (date.getFullYear() - 1))

        let end = new Date(startDate)
        end.setFullYear(end.getFullYear() + 1)
        endDate = new Date((date.getMonth() + 1) + "-" + date.getDate() + "-" + end.getFullYear())
        endDate.setDate(endDate.getDate() - 1)

        return this.formatDateForPeriod(startDate) + " - " + this.formatDateForPeriod(endDate)
    },
    buildNextPeriodRangeForYear(date) {
        let startDate = ''
        let endDate = ''

        startDate = new Date((date.getMonth() + 1) + "-" + date.getDate() + "-" + (date.getFullYear() + 1))

        let end = new Date(startDate)
        end.setFullYear(end.getFullYear() + 1)
        endDate = new Date((date.getMonth() + 1) + "-" + date.getDate() + "-" + end.getFullYear())
        endDate.setDate(endDate.getDate() - 1)

        return this.formatDateForPeriod(startDate) + " - " + this.formatDateForPeriod(endDate)
    },
    buildPeriodRangeForYear(sDate, date) {
        let currentDay = new Date(this.returnFormattedDateFromCalendar(sDate))
        let startDate = ''
        let endDate = ''

        startDate = new Date(currentDay.getMonth() + "-" + currentDay.getDate() + "-" + date.getFullYear())

        let end = new Date(currentDay)
        end.setFullYear(end.getFullYear() + 1)
        endDate = new Date(currentDay.getMonth() + "-" + currentDay.getDate() + "-" + end.getFullYear())
        endDate.setDate(endDate.getDate() - 1)

        return this.formatDateForPeriod(startDate) + " - " + this.formatDateForPeriod(endDate)
    },
    returnCurrentStartDateForBiWeekly(start, date) {
        let currentDateReached = false
        let nextDate = new Date(start)
        let currentStart = new Date(start)

        while (!currentDateReached) {
            nextDate = nextDate.setDate(nextDate.getDate() + 14)

            if (nextDate > date) {
                currentDateReached = !currentDateReached

                return currentStart
            }

            currentStart = nextDate
        }
    },
    returnStartDateForPeriodWeek(startDay, sentDate) {
        let date = this.getMonday(sentDate)

        switch (startDay) {
            case "Monday":
                return date;
                break;
            case "Tuesday":
                return date.setDate(date.getDate() + 1);
                break;
            case "Wednesday":
                return date.setDate(date.getDate() + 2);
                break;
            case "Thursday":
                return date.setDate(date.getDate() + 3);
                break;
            case "Friday":
                return date.setDate(date.getDate() + 4);
                break;
            case "Saturday":
                return date.setDate(date.getDate() + 5);
                break;
            case "Sunday":
                return date.setDate(date.getDate() + 6);
                break;
        }
    },
    returnEndDateForPeriodWeek(startDay) {
        return startDay.setDate(startDay.getDate() + 6);
    },
    returnEndDateForPeriodBiWeek(startDay) {
        return startDay.setDate(startDay.getDate() + 13);
    },
    getMonday(d) {
        d = new Date(d);
        var day = d.getDay(),
            diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
        return new Date(d.setDate(diff));
    },
    returnFormattedDateFromCalendar(date) {
        let dateSplit = date.split("-")
            
        return (dateSplit[0] + "-" + (parseInt(dateSplit[1]) + 1) + "-" + (parseInt(dateSplit[2])))
    },
    formatDateForPeriod(date) {
        let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
        let mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(date);
        let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);

        return da + "-" + mo + "-" + ye 
    }

}