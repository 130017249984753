import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import { store } from './store/storeMain';
import vuetify from './plugins/vuetify';
import Vuelidate from 'vuelidate';


Vue.use(vuetify, {
    theme: {
        primary: "#2196F3",
        secondary: "#26A69A",
        accent: "#80CBC4",
        error: "#f44336",
        warning: "#ffeb3b",
        info: "#2196f3",
        success: "#4caf50"
    }
});

Vue.use(Vuelidate);

Vue.config.productionTip = false

new Vue({
  router,
  store: store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
