import { HTTP } from './httpCommon';

export const httpPut = {

  ////////////////////////////////////////////
  ///ACCOUNTS
  ////////////////////////////////////////////

  //Update Account
  updateAccount(account, callback) {
    HTTP.put('Accounts/' +
      account.id, account
    )
      .then(response => {
        callback(response.status)
      })
      .catch(e => {
        //this.errors.push(e)
        console.log(e);
      })

  },

  ////////////////////////////////////////////
  ///TRANSACTIONS
  ////////////////////////////////////////////

  //Update Transaction
  updateTransaction(transaction, callback) {
    HTTP.put('Transactions/' +
        transaction.id, transaction
    )
      .then(response => {
        callback(response.status)
      })
      .catch(e => {
        //this.errors.push(e)
        console.log(e);
      })

  },


  ////////////////////////////////////////////
  ///ITEMS
  ////////////////////////////////////////////

  //Update Items
  updateItem(item, callback) {
    HTTP.put('Items/' +
      item.id, item
    )
      .then(response => {
        callback(response.status)
      })
      .catch(e => {
        this.errors.push(e)
        console.log(e);
      })

    },


    ////////////////////////////////////////////
    ///PLACESOLD
    ////////////////////////////////////////////

    //Update PlaceSold
    updatePlaceSold(placeSold, callback) {
        HTTP.put('PlaceSold/' +
            placeSold.id, placeSold
        )
            .then(response => {
                callback(response.status)
            })
            .catch(e => {
                this.errors.push(e)
                console.log(e);
            })

    },


  ////////////////////////////////////////////
  ///BUDGETS
  ////////////////////////////////////////////

  //Update Budgets
  updateBudget(budget, callback) {
    HTTP.put('Budgets/' +
      budget.id, budget
    )
      .then(response => {
        callback(response.status)
      })
      .catch(e => {
        //this.errors.push(e)
        console.log(e);
      })

  },

  
  ////////////////////////////////////////////
  ///CURRENCIES
  ////////////////////////////////////////////

  //Update Currency
  updateCurrency(currency, callback) {
    HTTP.put('Currencies/' +
      currency.id, currency
    )
      .then(response => {
        callback(response.status)
      })
      .catch(e => {
        //this.errors.push(e)
        console.log(e);
      })

  },


  ////////////////////////////////////////////
  ///USER
  ////////////////////////////////////////////

  //Update User
  updateUser(user, callback) {
    HTTP.put('Profile/' +
      user.id, user
    )
      .then(response => {
        callback(response.status)
      })
      .catch(e => {
        //this.errors.push(e)
        console.log(e);
      })

  },

}
