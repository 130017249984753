import pjson from '../../package.json';

export const mutations = {

  //////////////////////////////////////////////////////////////////////////
  //LocalStorage Mutations
  //////////////////////////////////////////////////////////////////////////

  initialiseStore(state) {
    // Check if the store exists
    if (localStorage.getItem('store')) {
      let store = JSON.parse(localStorage.getItem('store'))

       //Check the version stored against current. If different, don't
       //load the cached version
      if (store.version === pjson.version) {
        this.replaceState(Object.assign(state, store))
      } else {
        state.version = pjson.version
      }
    }
  },

  //////////////////////////////////////////////////////////////////////////
  //End Of LocalStorage Mutations
  //////////////////////////////////////////////////////////////////////////


  //////////////////////////////////////////////////////////////////////////
  //Account Mutations
  //////////////////////////////////////////////////////////////////////////

  updateStoreWithAccountsDataFromAPI: (state, accounts) => {
    if (accounts.length > 0) {
      for (var i = 0; i < accounts.length; i++) {
        let ifExist = true

        for (var x = 0; x < state.Accounts.length; x++) {
          if (state.Accounts[x].id === accounts[i].id) { ifExist = false }
        }

        if (ifExist) {
              state.Accounts.push(accounts[i])
        }
      }
    }
  },
  addNewAccountToStore: (state, account) => {
    state.Accounts.push(account)
  },
  updateAccountinStore: (state, account) => {
    for (let i = 0; i < state.Accounts.length; i++) {
      if (state.Accounts[i].id === account.id) {
        state.Accounts[i].accountName = account.accountName
        state.Accounts[i].accountType = account.accountType
        state.Accounts[i].accountDescription = account.accountDescription
        state.Accounts[i].accountAmount = account.accountAmount
        state.Accounts[i].accountCurrency = account.accountCurrency
        state.Accounts[i].objectState = "Update"
        break
      }
    }
    },
    updateStoreAfterPost: (state, account) => {
        for (let i = 0; i < state.Accounts.length; i++) {
            if (state.Accounts[i].accountName === account.accountName) {
                state.Accounts[i].id = account.id
                state.Accounts[i].accountType = account.accountType
                state.Accounts[i].accountDescription = account.accountDescription
                state.Accounts[i].accountAmount = account.accountAmount
                state.Accounts[i].accountCurrency = account.accountCurrency
                state.Accounts[i].objectState = account.objectState
                break
            }
        }
    },
  markAccountAsDeleted: (state, account) => {
    for (var i = 0; i < state.Accounts.length; i++) {
      if (state.Accounts[i].id === account.id) {
        state.Accounts[i].objectState = account.objectState
        break
      }
    }
  },
  deleteAccountfromStore: (state, account) => {
    for (var i = 0; i < state.Accounts.length; i++) {
      if (state.Accounts[i].id === account.id) {
        state.Accounts.splice(i, 1)
        break
      }
    }
  },

  //////////////////////////////////////////////////////////////////////////
  //End Of Account Mutations
  //////////////////////////////////////////////////////////////////////////


  //////////////////////////////////////////////////////////////////////////
  //Category Mutations
  //////////////////////////////////////////////////////////////////////////

  updateStoreWithCategoryDataFromAPI: (state, data) => {

    let category = data

    if (category.length > 0) {
      for (var i = 0; i < category.length; i++) {
        let ifExist = true

        for (var x = 0; x < state.Categories.length; x++) {
          if (state.Categories[x].id === category[i].id) { ifExist = false }
        }

        if (ifExist) {
          state.Categories.push(category[i])
        }
      }
    }
  },
  addNewCategoryToStore: (state, category) => {
    state.Categories.push(category)
  },
  updateCategoryinStore: (state, category) => {
    for (let i = 0; i < state.Categories.length; i++) {
      if (state.Categories[i].id === category.id) {
        state.Categories[i].categoryName = category.categoryName
        state.Categories[i].iconSize = category.iconSize
        state.Categories[i].iconData = category.iconData
        state.Categories[i].parentCategory = category.parentCategory
        state.Categories[i].userDefined = category.userDefined
        state.Categories[i].userId = category.userId
        state.Categories[i].objectState = category.objectState
        break
      }
    }

  },
  markCategoryAsDeleted: (state, category) => {
    for (var i = 0; i < state.Categories.length; i++) {
      if (state.Categories[i].id === category.id) {
        state.Categories[i].objectState = category.objectState
        break
      }
    }
  },
  deleteCategoryfromStore: (state, category) => {
    for (var i = 0; i < state.Categories.length; i++) {
      if (state.Categories[i].id === category.id) {
        state.Categories.splice(i, 1)
        break
      }
    }
  },

  //////////////////////////////////////////////////////////////////////////
  //End Of Category Mutations
  //////////////////////////////////////////////////////////////////////////


  //////////////////////////////////////////////////////////////////////////
  //Income Mutations
  //////////////////////////////////////////////////////////////////////////
  
  updateStoreWithIncomeDataFromAPI: (state, data) => {

    let incomes = data

    if (incomes.length > 0) {
      for (var i = 0; i < incomes.length; i++) {
        let ifExist = true

        for (var x = 0; x < state.Incomes.length; x++) {
          if (state.Incomes[x].id === incomes[i].id) { ifExist = false }
        }

        if (ifExist) {
          state.Incomes.push(incomes[i])
        }
      }
    }
  },
  addNewIncomeToStore: (state, income) => {
    state.Incomes.push(income)
  },
  updateIncomeinStore: (state, income) => {
    for (let i = 0; i < state.Incomes.length; i++) {
      if (state.Incomes[i].id === income.id) {
        state.Incomes[i].incomeName = income.incomeName
        state.Incomes[i].incomeType = income.incomeType
        state.Incomes[i].incomeAmount = income.incomeAmount
        state.Incomes[i].incomeDate = income.incomeDate
        state.Incomes[i].comment = income.comment
        state.Incomes[i].category = income.category
        state.Incomes[i].account = income.account
        state.Incomes[i].repeat = income.repeat
        state.Incomes[i].cycle = income.cycle
        state.Incomes[i].period = income.period
        state.Incomes[i].numberOfCycles = income.numberOfCycles
        state.Incomes[i].goesOffFirst = income.goesOffFirst
        state.Incomes[i].objectState = income.objectState
        break
      }
    }

  },
  markIncomeAsDeleted: (state, income) => {
    for (var i = 0; i < state.Incomes.length; i++) {
      if (state.Incomes[i].id === income.id) {
        state.Incomes[i].objectState = income.objectState
        break
      }
    }
  },
  deleteIncomefromStore: (state, income) => {
    for (var i = 0; i < state.Incomes.length; i++) {
      if (state.Incomes[i].id === income.id) {
        state.Incomes.splice(i, 1)
        break
      }
    }
  },

  //////////////////////////////////////////////////////////////////////////
  //End Of Income Mutations
  //////////////////////////////////////////////////////////////////////////


  //////////////////////////////////////////////////////////////////////////
  //Expense Mutations
  //////////////////////////////////////////////////////////////////////////
  
  updateStoreWithExpenseDataFromAPI: (state, data) => {

    let expenses = data

    if (expenses.length > 0) {
      for (var i = 0; i < expenses.length; i++) {
        let ifExist = true

        for (var x = 0; x < state.Expenses.length; x++) {
          if (state.Expenses[x].id === expenses[i].id) { ifExist = false }
        }

        if (ifExist) {
          state.Expenses.push(expenses[i])
        }
      }
    }
  },
  addNewExpenseToStore: (state, expense) => {
    state.Expenses.push(expense)
  },
  updateExpenseinStore: (state, expense) => {
    for (let i = 0; i < state.Expenses.length; i++) {
      if(state.Expenses[i].id === expense.id) {
        state.Expenses[i].expenseName = expense.expenseName
        state.Expenses[i].expenseType = expense.expenseType
        state.Expenses[i].expenseAmount = expense.expenseAmount
        state.Expenses[i].expenseDate = expense.expenseDate
        state.Expenses[i].expenseTime = expense.expenseTime
        state.Expenses[i].comment = expense.comment
        state.Expenses[i].category = expense.category
        state.Expenses[i].paymentType = expense.paymentType
        state.Expenses[i].items = expense.items
        state.Expenses[i].account = expense.account
        state.Expenses[i].budget = expense.budget
        state.Expenses[i].foreignCurrencyAmount = expense.foreignCurrencyAmount
        state.Expenses[i].currencyConversion = expense.currencyConversion
        state.Expenses[i].repeat = expense.repeat
        state.Expenses[i].cycle = expense.cycle
        state.Expenses[i].period = expense.period
        state.Expenses[i].numberOfCycles = expense.numberOfCycles
        state.Expenses[i].goesOffFirst = expense.goesOffFirst
        state.Expenses[i].objectState = expense.objectState
        break
      }
    }

  },
  markExpenseAsDeleted: (state, expense) => {
    for (var i = 0; i < state.Expenses.length; i++) {
      if (state.Expenses[i].id === expense.id) {
        state.Expenses[i].objectState = expense.objectState
        break
      }
    }
  },
  deleteExpensefromStore: (state, expense) => {
    for (var i = 0; i < state.Expenses.length; i++) {
      if (state.Expenses[i].id === expense.id) {
        state.Expenses.splice(i, 1)
        break
      }
    }
  },

  //////////////////////////////////////////////////////////////////////////
  //End Of Expense Mutations
  //////////////////////////////////////////////////////////////////////////


  //////////////////////////////////////////////////////////////////////////
  //Budget Mutations
  //////////////////////////////////////////////////////////////////////////

  updateStoreWithBudgetDataFromAPI: (state, data) => {

    let budgets = data

    if (budgets.length > 0) {
      for (var i = 0; i < budgets.length; i++) {
        let ifExist = true

        for (var x = 0; x < state.Budgets.length; x++) {
          if (state.Budgets[x].id === budgets[i].id) { ifExist = false }
        }

        if (ifExist) {
          state.Budgets.push(budgets[i])
        }
      }
    }
  },
  addNewBudgetToStore: (state, budget) => {
    state.Budgets.push(budget)
  },
  updateBudgetinStore: (state, budget) => {
    for (let i = 0; i < state.Budgets.length; i++) {
      if (state.Budgets[i].id === budget.id) {
        state.Budgets[i].budgetName = budget.budgetName
        state.Budgets[i].budgetType = budget.budgetType
        state.Budgets[i].budgetAmount = budget.budgetAmount
        state.Budgets[i].comment = budget.comment
        state.Budgets[i].category = budget.category
        state.Budgets[i].account = budget.account
        state.Budgets[i].amountSpent = budget.amountSpent
        state.Budgets[i].refillAmount = budget.refillAmount
        state.Budgets[i].budgetOverspent = budget.budgetOverspent
        state.Budgets[i].OverspendingRisk = budget.OverspendingRisk
        state.Budgets[i].objectState = budget.objectState
        break
      }
    }
  },
  markBudgetAsDeleted: (state, budget) => {
    for (var i = 0; i < state.Budgets.length; i++) {
      if (state.Budgets[i].id === budget.id) {
        state.Budgets[i].objectState = budget.objectState
        break
      }
    }
  },
  deleteBudgetfromStore: (state, budget) => {
    for (var i = 0; i < state.Budgets.length; i++) {
      if (state.Budgets[i].id === budget.id) {
        state.Budgets.splice(i, 1)
        break
      }
    }
  },

  //////////////////////////////////////////////////////////////////////////
  //End Of Budget Mutations
  //////////////////////////////////////////////////////////////////////////


  //////////////////////////////////////////////////////////////////////////
  //Transaction Mutations
  //////////////////////////////////////////////////////////////////////////

  updateStoreWithTransactionDataFromAPI: (state, data) => {

    let transactions = data

    if (transactions.length > 0) {
      for (var i = 0; i < transactions.length; i++) {
        let ifExist = true

        for (var x = 0; x < state.Transactions.length; x++) {
          if (state.Transactions[x].id === transactions[i].id) { ifExist = false }
        }

        if (ifExist) {
          state.Transactions.push(transactions[i])
        }
      }
    }
  },
  addNewTransactionToStore: (state, transaction) => {
    state.Transactions.push(transaction)
  },
  updateTransactioninStore: (state, transaction) => {
    for (let i = 0; i < state.Transactions.length; i++) {
      if (state.Transactions[i].id === transaction.id) {
        state.Transactions[i].budget = transaction.budget
        state.Transactions[i].cycle = transaction.cycle
        state.Transactions[i].goesOffFirst = transaction.goesOffFirst
        state.Transactions[i].transactionBalances[0].transactionItems = transaction.transactionBalances[0].transactionItems
        state.Transactions[i].numberOfCycles = transaction.numberOfCycles
        state.Transactions[i].transactionBalances[0].paymentType = transaction.transactionBalances[0].paymentType
        state.Transactions[i].period = transaction.period
        state.Transactions[i].recurringTransaction = transaction.recurringTransaction
        state.Transactions[i].repeat = transaction.repeat
        state.Transactions[i].show = transaction.show
        state.Transactions[i].transactionType = transaction.transactionType
        state.Transactions[i].transactionBalances[0].transactionAmount = transaction.transactionBalances[0].transactionAmount
        state.Transactions[i].comment = transaction.comment
        state.Transactions[i].category = transaction.category
        state.Transactions[i].account = transaction.account
        state.Transactions[i].currency = transaction.currency
        state.Transactions[i].transactionBalances[0].transactionDate = transaction.transactionBalances[0].transactionDate
        state.Transactions[i].transactionBalances[0].transactionTime = transaction.transactionBalances[0].transactionTime
        state.Transactions[i].transactionBalances[0].changed = transaction.transactionBalances[0].changed
        state.Transactions[i].transactionName = transaction.transactionName
        state.Transactions[i].transferFrom = transaction.transferFrom
        state.Transactions[i].transferTo = transaction.transferTo
        state.Transactions[i].objectState = transaction.objectState
        break
      }
    }
  },
  markTransactionAsDeleted: (state, transaction) => {
    for (var i = 0; i < state.Transactions.length; i++) {
      if (state.Transactions[i].id === transaction.id) {
        state.Transactions[i].objectState = transaction.objectState
        break
      }
    }
  },
  deleteTransactionfromStore: (state, transaction) => {
    for (var i = 0; i < state.Transactions.length; i++) {
      if (state.Transactions[i].id === transaction.id) {
        state.Transactions.splice(i, 1)
        break
      }
    }
  },

  //////////////////////////////////////////////////////////////////////////
  //End Of Transaction Mutations
  //////////////////////////////////////////////////////////////////////////


  //////////////////////////////////////////////////////////////////////////
  //Item Mutations
  //////////////////////////////////////////////////////////////////////////

  updateStoreWithItemDataFromAPI: (state, data) => {

    let items = data

    if (items.length > 0) {
      for (var i = 0; i < items.length; i++) {
        let ifExist = true

        for (var x = 0; x < state.Items.length; x++) {
          if (state.Items[x].id === items[i].id) { ifExist = false }
        }

        if (ifExist) {
          state.Items.push(items[i])
        }
      }
    }
  },
  addNewItemToStore: (state, item) => {
    state.Items.push(item)
  },
  updateIteminStore: (state, item) => {
    for (let i = 0; i < state.Items.length; i++) {
      if (state.Items[i].id === item.id) {
        state.Items[i].itemName = item.itemName
        state.Items[i].itemPrice = item.itemPrice
        state.Items[i].itemCurrency = item.itemCurrency
        state.Items[i].isTaxed = item.isTaxed
        state.Items[i].taxAmount = item.taxAmount
        state.Items[i].dateChanged = item.dateChanged
        state.Items[i].objectState = item.objectState
        break
      }
    }
  },

  //////////////////////////////////////////////////////////////////////////
  //End Of Item Mutations
  //////////////////////////////////////////////////////////////////////////


  //////////////////////////////////////////////////////////////////////////
  //Currency Mutations
  //////////////////////////////////////////////////////////////////////////

  updateStoreWithCurrencyDataFromAPI: (state, data) => {

    let currencies = data

    if (currencies.length > 0) {
      for (var i = 0; i < currencies.length; i++) {
        let ifExist = true

        for (var x = 0; x < state.Currencies.length; x++) {
          if (state.Currencies[x].id === currencies[i].id) { ifExist = false }
        }

        if (ifExist) {
          state.Currencies.push(currencies[i])
        }
      }
    }
  },
  addNewCurrencyToStore: (state, currency) => {
    state.Currencies.push(currency)
  },
  deleteCurrencyfromStore: (state, currency) => {
    for (var i = 0; i < state.Currencies.length; i++) {
      if (state.Currencies[i].id === currency.id) {
        state.Currencies.splice(i, 1)
        break
      }
    }
  },

  //////////////////////////////////////////////////////////////////////////
  //End Of Currency Mutations
  //////////////////////////////////////////////////////////////////////////


  //////////////////////////////////////////////////////////////////////////
  //PlaceSold Mutations
  //////////////////////////////////////////////////////////////////////////

    updateStoreWithPlaceSoldDataFromAPI: (state, placeSold) => {
        if (placeSold.length > 0) {
            for (let i = 0; i < placeSold.length; i++) {
                let ifExist = true

                for (let x = 0; x < state.PlaceSold.length; x++) {
                    if (state.PlaceSold[x].id === placeSold[i].id) { ifExist = false }
                }

                if (ifExist) {
                    state.PlaceSold.push(placeSold[i])
                }
            }
        }
    },
    addNewPlaceSoldToStore: (state, placeSold) => {
        state.PlaceSold.push(placeSold)
    },

  //////////////////////////////////////////////////////////////////////////
  //End Of PlaceSold Mutations
  //////////////////////////////////////////////////////////////////////////


    //////////////////////////////////////////////////////////////////////////
    //PlaceSold Mutations
    //////////////////////////////////////////////////////////////////////////

    updateStoreWithAuditDataFromAPI: (state, audit) => {
        if (placeSold.length > 0) {
            for (let i = 0; i < placeSold.length; i++) {
                let ifExist = true

                for (let x = 0; x < state.PlaceSold.length; x++) {
                    if (state.PlaceSold[x].id === placeSold[i].id) { ifExist = false }
                }

                if (ifExist) {
                    state.PlaceSold.push(placeSold[i])
                }
            }
        }
    },
    addNewAuditToStore: (state, audit) => {
        state.Audit.push(audit)
    },

  //////////////////////////////////////////////////////////////////////////
  //End Of PlaceSold Mutations
  //////////////////////////////////////////////////////////////////////////


  //////////////////////////////////////////////////////////////////////////
  //Currency Conversion Mutations
  //////////////////////////////////////////////////////////////////////////

  addNewCurrencyConversionToStore: (state, conversion) => {
    state.CurrencyConversions.push(conversion)
  },

  //////////////////////////////////////////////////////////////////////////
  //End Of Currency Conversion Mutations
  //////////////////////////////////////////////////////////////////////////


  //////////////////////////////////////////////////////////////////////////
  //Transfer Mutations
  //////////////////////////////////////////////////////////////////////////

  addNewTransferToStore: (state, transfer) => {
    state.Transfers.push(transfer)
  },
  updateTransferinStore: (state, transfer) => {
    for (let i = 0; i < state.Transfers.length; i++) {
      if (state.Transfers.id === transfer.id) {
        state.Transfers[i].currency = transfer.currency
        state.Transfers[i].transferName = transfer.transferName
        state.Transfers[i].category = transfer.category
        state.Transfers[i].transferAmount = transfer.transferAmount
        state.Transfers[i].comment = transfer.comment
        state.Transfers[i].paymentType = transfer.paymentType
        state.Transfers[i].transferFrom = transfer.transferFrom
        state.Transfers[i].transferTo = transfer.transferTo
        state.Transfers[i].transferDate = transfer.transferDate
        state.Transfers[i].transferTime = transfer.transferTime
        state.Transfers[i].objectState = transfer.objectState
        break
      }
    }
  },
  markTransferAsDeleted: (state, transfer) => {
    for (var i = 0; i < state.Transfers.length; i++) {
      if (state.Transfers[i].id === transfer.id) {
        state.Transfers[i].objectState = transfer.objectState
        break
      }
    }
  },

  //////////////////////////////////////////////////////////////////////////
  //End Of Item Mutations
  //////////////////////////////////////////////////////////////////////////

   
  //////////////////////////////////////////////////////////////////////////
  //GCT Mutations
  //////////////////////////////////////////////////////////////////////////

  addNewGCTToStore: (state, gct) => {
    state.GCT.push(gct)
  },

  //////////////////////////////////////////////////////////////////////////
  //End Of GCT Mutations
  //////////////////////////////////////////////////////////////////////////


  //////////////////////////////////////////////////////////////////////////
  //User Mutations
  //////////////////////////////////////////////////////////////////////////

  updateStoreWithUserDataFromAPI: (state, user) => {
      state.User = user
  },

  //////////////////////////////////////////////////////////////////////////
  //End of User Mutations
  //////////////////////////////////////////////////////////////////////////


  //////////////////////////////////////////////////////////////////////////
  //Settings Mutations
  //////////////////////////////////////////////////////////////////////////

    updateSelectPeriodInStore: (state, period) => {
        state.Settings = {
            period: '',
            periodStartDayOfWeek: '',
            periodStartDay: '',
            periodStartDate: ''
        }

        state.Settings.period = period.period
        state.Settings.periodStartDayOfWeek = period.periodStartDayOfWeek
        state.Settings.periodStartDate = period.periodStartDate
        state.Settings.periodStartDay = period.periodStartDay
    },

  //////////////////////////////////////////////////////////////////////////
  //End of Settings Mutations
  //////////////////////////////////////////////////////////////////////////


  //////////////////////////////////////////////////////////////////////////
  //Update Night Mode Selection
  //////////////////////////////////////////////////////////////////////////

  UpdateNightModeSelection: (state, selection) => {
    state.NightMode.switch = selection
  }

  //////////////////////////////////////////////////////////////////////////
  //End Of Update Night Mode Selection
  //////////////////////////////////////////////////////////////////////////


}
