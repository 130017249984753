import { HTTP } from './httpCommon';

export const httpGet = {

  ////////////////////////////////////////////
  ///ACCOUNTS
  ////////////////////////////////////////////

  //Get Accounts
  getAccounts(callback) {
    HTTP.get('Accounts')
      .then(response => {
        // JSON responses are automatically parsed.
        callback(response.data)
      })
      .catch(e => {
        //this.errors.push(e)
      })
  },


  ////////////////////////////////////////////
  ///TRANSACTIONS
  ////////////////////////////////////////////

  //Get Transaction
  getTransactions(callback) {
    HTTP.get('Transactions')
      .then(response => {
        // JSON responses are automatically parsed.
        callback(response.data)
      })
      .catch(e => {
        //this.errors.push(e)
      })
  },


  ////////////////////////////////////////////
  ///ITEMS
  ////////////////////////////////////////////

  //Get Items
  getItems(query, callback) {
    HTTP.get('Items', query)
      .then(response => {
        // JSON responses are automatically parsed.
        callback(response.data)
      })
      .catch(e => {
        //this.errors.push(e)
      })
  },


    ////////////////////////////////////////////
    ///PLACESOLD
    ////////////////////////////////////////////

    //Get PlaceSold
    getPlaceSold(callback) {
        HTTP.get('PlaceSold')
            .then(response => {
                // JSON responses are automatically parsed.
                callback(response.data)
            })
            .catch(e => {
                //this.errors.push(e)
            })
    },


  ////////////////////////////////////////////
  ///BUDGETS
  ////////////////////////////////////////////

  //Get Budgets
  getBudgets(callback) {
    HTTP.get('Budgets')
      .then(response => {
        // JSON responses are automatically parsed.
        callback(response.data)
      })
      .catch(e => {
        //this.errors.push(e)
      })
  },


  ////////////////////////////////////////////
  ///CATEGORIES
  ////////////////////////////////////////////

  //Get Categories
  getCategories(callback) {
    HTTP.get('Categories')
      .then(response => {
        // JSON responses are automatically parsed.
        callback(response.data)
      })
      .catch(e => {
        //this.errors.push(e)
      })
  },


  ////////////////////////////////////////////
  ///CURRENCIES
  ////////////////////////////////////////////

  //Get Currencies
  getCurrencies(callback) {
    HTTP.get('Currencies')
      .then(response => {
        // JSON responses are automatically parsed.
        callback(response.data)
      })
      .catch(e => {
        //this.errors.push(e)
      })
  },

    ////////////////////////////////////////////
    ///AUDIT
    ////////////////////////////////////////////

    //Get Audit
    getAudit(callback) {
        HTTP.get('Audit')
            .then(response => {
                // JSON responses are automatically parsed.
                callback(response.data)
            })
            .catch(e => {
                //this.errors.push(e)
            })
    },


  ///////////////////////////////////////////////
  ///USER PROFILE
  ///////////////////////////////////////////////

  //Get User Profile
  getUser(callback) {
    HTTP.get('Profile'
    )
      .then(response => {
        // JSON responses are automatically parsed.
        callback(response.data)
      })
      .catch(e => {
        //this.errors.push(e)
      })
  },

    ///////////////////////////////////////////////
    ///PERIOD
    ///////////////////////////////////////////////

    //Get Period
    getPeriod(callback) {
        HTTP.get('Period'
        )
            .then(response => {
                // JSON responses are automatically parsed.
                callback(response.data)
            })
            .catch(e => {
                //this.errors.push(e)
            })
    },


  

}
