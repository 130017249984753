import { store } from '../store/storeMain'

export const globalFunctions = {

  ///////////////////////////////////////////////////////////
  //Return Api Path
  //////////////////////////////////////////////////////////

    returnApiPath() {
        let domain = document.domain
        let baseURL

        if (domain.indexOf('localhost') !== -1) {
            //LocalHost 
            baseURL = 'http://localhost:57896/api/'
        }
        else if (domain.indexOf('testapi.mywalletwatcher') !== -1) {
            //Test Environment
            baseURL = 'https://testapi.mywalletwatcher.com/api/'
        }
        else if (domain.indexOf('api.mywalletwatcher') !== -1) {
            //Production
            baseURL = 'https://api.mywalletwatcher.com/api/'
        }
        else {
            //Production
            baseURL = 'https://api.mywalletwatcher.com/api/'
        }

        return baseURL
    },

  ///////////////////////////////////////////////////////////
  //Check if Null, Empty or Undefined
  ///////////////////////////////////////////////////////////

  isNullOrEmpty(value) {
    if (value !== '' && value !== null && value !== undefined) {
      return false
    }
    return true
  },


  ///////////////////////////////////////////////////////////
  //Return Available Currencies
  ///////////////////////////////////////////////////////////

    returnAvailableCurrencies() {
        let cur = store.state.Currencies
        let currencies = []

        for (let i = 0; i < cur.length; i++) {
            if (cur[i].ObjectState !== "Delete") {
                currencies.push(cur[i])
            }
        }
        return currencies
    },


  ///////////////////////////////////////////////////////////
  //Return Available Place Sold
  ///////////////////////////////////////////////////////////

    returnAvailablePlaceSold() {
        let ps = store.state.PlaceSold
        let placeSold = []

        for (let i = 0; i < ps.length; i++) {
            if (ps[i].objectState !== "Delete") {
                placeSold.push(ps[i])
            }
        }
        return placeSold
    },


  ///////////////////////////////////////////////////////////
  //Return Categories
  ///////////////////////////////////////////////////////////

    returnCategories() {
        let cat = store.state.Categories
        let category = []

        for (let i = 0; i < cat.length; i++) {
            category.push(cat[i].categoryName)
        }
        return category
    },


  ///////////////////////////////////////////////////////////
  //Returns True If Int Or Float
  ///////////////////////////////////////////////////////////

  isIntOrFloat(value) {
    if (/^-{0,1}\d*\.{0,1}\d+$/.test(value)) {
      return true
    }
    return false
  },


  ///////////////////////////////////////////////////////////
  //Returns the Current Date
  ///////////////////////////////////////////////////////////

  getCurrentDate() {
    let currentdate = new Date()

    return currentdate.getDate() + "-"
      + (currentdate.getMonth() + 1) + "-"
      + currentdate.getFullYear()
  },


  ///////////////////////////////////////////////////////////
  //Returns The Current Date For DatePicker
  ///////////////////////////////////////////////////////////

  getCurrentDateForPicker() {
    let date = new Date()

    let month = date.getMonth() + 1
    if (month < 10) {
      month = "0" + month
    }

    let day = date.getDate()
    if (day < 10) {
      day = "0" + day
    }

    return date.getFullYear() + "-" + month + "-" + day
  },


  ///////////////////////////////////////////////////////////
  //Returns The Current Time For TimePicker
  ///////////////////////////////////////////////////////////

  getCurrentTimeForPicker() {
    let date = new Date()

    return date.getHours() + ":" + date.getMinutes()
  },

  ///////////////////////////////////////////////////////////
  //Returns Amount In Account
  ///////////////////////////////////////////////////////////

  getAccountCurrentAmount(account, acc) {
    
    for (let i = 0; i < acc.length; i++) {
      if (acc[i].accountName === account) {
        return acc[i].accountAmount
      }
    }

  },


  ///////////////////////////////////////////////////////////
  //Returns Amount In Budget
  ///////////////////////////////////////////////////////////

  getBudgetCurrentAmount(budget, bud) {
   
    for (let i = 0; i < bud.length; i++) {
      if (bud[i].budgetName === budget) {
        return bud[i].budgetAmount
      }
    }

  },


  ///////////////////////////////////////////////////////////
  //Returns New Id Number
  ///////////////////////////////////////////////////////////

  getNextIdForObject(object) {
    let largest = 0
    for (let i = 0; i < object.length; i++) {
      if (object[i].id > largest) {
        largest = object[i].id
      }
    }
    return (largest + 1)
  },

  ///////////////////////////////////////////////////////////
  //Returns items minus deleted items
  ///////////////////////////////////////////////////////////

  removeDeletedObjectsFromItems(items) {
    for (let i = 0; i < items.length; i++) {
      if (items[i].objectState === 'Delete') {
        items.splice(i, 1)
      }
    }

    return this.stringify(items)
  },

  ///////////////////////////////////////////////////////////
  //Returns First Letter Capitalized
  ///////////////////////////////////////////////////////////

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  },

  ///////////////////////////////////////////////////////////
  //Decouples object so changes will not be reflected 
  ///////////////////////////////////////////////////////////
  stringify(obj) {
    return JSON.parse(JSON.stringify(obj))
  },

  ///////////////////////////////////////////////////////////
  //Shows Filter/Search and hides the other is showing
  ///////////////////////////////////////////////////////////
  viewFilterSearchChanged(speedDial, change) {

    if (change === "search") {
      speedDial.viewSearch = !speedDial.viewSearch
      speedDial.viewFilter = false
    }
    else if (change === "filter") {
      speedDial.viewFilter = !speedDial.viewFilter
      speedDial.viewSearch = false
    }

    return speedDial
  },


  ///////////////////////////////////////////////////////////
  //Formats money to have commas in the right places
  ///////////////////////////////////////////////////////////
    formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {
        try {
            decimalCount = Math.abs(decimalCount);
            decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

            const negativeSign = amount < 0 ? "-" : "";

            let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
            let j = (i.length > 3) ? i.length % 3 : 0;

            return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
        } catch (e) {
            console.log(e)
        }
    }

}

///////////////////////////////////////////////////////////
//Decouples object so changes will not be reflected 
///////////////////////////////////////////////////////////

export const stringify = (obj) => {
    return JSON.parse(JSON.stringify(obj))
}

///////////////////////////////////////////////////////////
//Return Available Place Sold
///////////////////////////////////////////////////////////

export const returnAvailablePlaceSold = () => {
    let ps = store.state.PlaceSold
    let placeSold = []

    for (let i = 0; i < ps.length; i++) {
        if (ps[i].objectState !== "Delete") {
            placeSold.push(ps[i])
        }
    }
    return placeSold
}

///////////////////////////////////////////////////////////
//Return List of Category Objects
///////////////////////////////////////////////////////////

export const returnCategoriesObject = () => {
    let cat = store.state.Categories
    let category = []

    for (let i = 0; i < cat.length; i++) {
        category.push(cat[i])
    }
    return category
}


///////////////////////////////////////////////////////////
//Return List of Category Names
///////////////////////////////////////////////////////////

export const returnCategoryNames = () => {
    let cat = store.state.Categories
    let category = []

    for (let i = 0; i < cat.length; i++) {
        category.push(cat[i].categoryName)
    }
    return category
}


///////////////////////////////////////////////////////////
//Check if Null, Empty or Undefined
///////////////////////////////////////////////////////////

export const isNullEmptyOrUndefined = (value) => {
    if (value !== '' && value !== null && value !== undefined) {
        return false
    }
    return true
}

///////////////////////////////////////////////////////////
//Formats money to have commas in the right places
///////////////////////////////////////////////////////////

export const formatMoney = (amount, decimalCount = 2, decimal = ".", thousands = ",") => {
    try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        const negativeSign = amount < 0 ? "-" : "";

        let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
        let j = (i.length > 3) ? i.length % 3 : 0;

        return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
    } catch (e) {
        console.log(e)
    }
}