import axios from 'axios';
import { auth } from '../auth/index';
import { store } from '../store/storeMain'
import { globalFunctions } from '../javascript/globalFunctions'

export const HTTP = axios.create({

  //Enter Address for Api Here
  //baseURL: 'http://localhost:52794/api/',

  //Test Environment
  //baseURL: 'http://test.mywalletwatcher.com/api/',
  //baseURL: 'https://testapi.mywalletwatcher.com/api/',

  //Production
  //baseURL: 'https://api.mywalletwatcher.com/api/',

  baseURL: globalFunctions.returnApiPath(),

    headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        "WWW-Authenticate": 'Bearer ' + localStorage.getItem('access_token'),
        'Content-Type': 'application/json'
    },

    

})

//let isRefreshing = false;
//let subscribers = [];

//HTTP.interceptors.response.use(
//    function (response) {
//        return response;
//    },
//    function (error) {
//        const {
//            config,
//            response: { status, data }
//        } = error;

//        const originalRequest = config;

//        if (config.headers.Authorization.length < 10) {
//            router.push({ path: '/login' });
//            return Promise.reject(false);
//        }

//        if (originalRequest.url.includes("Login")) {
//            return Promise.reject(err);
//        }

//        if (status === 401 && config.headers.Authorization.length > 10 && localStorage.getItem('login') === false) {
//            if (!isRefreshing) {
//                isRefreshing = true;
//                store
//                    .dispatch("getRefreshToken",
//                        {
//                            "token": localStorage.getItem('access_token'),
//                            "refreshToken": localStorage.getItem('refresh_token')
//                        }
//                )
//                    .then(({ status }) => {
//                        if (status === 200 || status === 204) {
//                            isRefreshing = false;
//                        }
//                        subscribers = [];
//                    })
//                    .catch(error => {
//                        console.error(error);
//                    });
//            }

//            const requestSubscribers = new Promise(resolve => {
//                subscribeTokenRefresh(() => {
//                    resolve(axios(originalRequest));
//                });
//            });

//            onRefreshed();

//            return requestSubscribers;
//        }

//        if (localStorage.getItem('login')) {
//            localStorage.setItem('login', false)
//        }

//        console.log(error)
//    }
//);

//function subscribeTokenRefresh(cb) {
//    subscribers.push(cb);
//}

//function onRefreshed() {
//    subscribers.map(cb => cb());
//}

//subscribers = [];