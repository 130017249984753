import { store } from '../store/storeMain'
import { globalFunctions } from './globalFunctions'
import { dateManager } from './dateManager'


export const budgetManager = {
    budgetObject: {
        id: 0,
        budgetName: '',
        account: '',
        budgetType: '',
        budgetAmount: 0,
        amountSpent: 0,
        refillAmount: 0,
        //refillDate: '',
        currency: '',
        comment: '',
        category: [],

        //Notification
        budgetOverspent: true,
        overspendingRisk: true,

        //State of Current Object
        objectState: 'New',
    },
    returnBudgets() {
        return store.state.Budgets
    },
    findBudgetByName(name) {
        let budgets = this.returnBudgets()

        for (let i = 0; i < budgets.length; i++) {
            if (budgets[i].budgetName === name) {
                return budgets[i]
            }
        }
    },
    findBudgetById(id) {
        let budgets = this.returnBudgets()

        for (let i = 0; i < budgets.length; i++) {
            if (budgets[i].id === id) {
                return budgets[i]
            }
        }
    },
    updateBudgetAmountSpent(budget, budgetBalance, amount) {
        budgetBalance.amountSpent = parseFloat(budgetBalance.amountSpent) + parseFloat(amount)

        for (let i = 0; i < budget.budgetBalances.length; i++) {
            if (budget.budgetBalances[i].id === budgetBalance.id) {
                budget.budgetBalances[i].amountSpent = budgetBalance.amountSpent
                break
            }
        }

        this.updateBudgetInStore(budget)
    },
    updateBudgetInStore(budget) {
        store.commit('updateBudgetinStore', budget)
    },
    increaseBudgetAmountSpent(budgetName, amount, transactionDate) {
        let budget = this.findBudgetByName(budgetName)

        let budgetBalance = this.getActiveBudgetBalance(budget, transactionDate)

        this.updateBudgetAmountSpent(budget, budgetBalance, amount)
    },

    //////////////////////////////////////////////////
    //Get Active Budget Balance
    //////////////////////////////////////////////////

    getActiveBudgetBalance(budget, date) {

        for (let i = 0; i < budget.budgetBalances.length; i++) {
            if (budget.budgetType !== "None") {
                if (budget.budgetBalances[i].objectState === "Active"
                    || dateManager.isWithinDateRange(budget.budgetBalances[i].periodStart, budget.budgetBalances[i].periodEnd, date)) {

                    return budget.budgetBalances[i]
                }
            }
            return budget.budgetBalances[0]
        }
    },

    formatBudgetForViewComponent(budgets) {
        for (let i = 0; i < budgets.length; i++) {
            let budgetBalanceData = this.getActiveBudgetBalance(budgets[i], new Date())

            budgets[i].budgetAmount = budgetBalanceData.budgetAmount
            budgets[i].amountSpent = budgetBalanceData.amountSpent
        }

        return budgets
    },

    //////////////////////////////////////////////////
    //Convert To budget Format
    /////////////////////////////////////////////////

    formatAsBudget(budget) {
        this.budgetObject.id = budget.id
        this.budgetObject.budgetName = budget.budgetName
        this.budgetObject.account = budget.account
        this.budgetObject.category = budget.category
        this.budgetObject.currency = budget.currency
        this.budgetObject.budgetType = budget.budgetType
        this.budgetObject.budgetAmount = parseFloat(budget.budgetAmount)
        this.budgetObject.amountSpent = parseFloat(budget.amountSpent)
        this.budgetObject.refillAmount = parseFloat(budget.refillAmount)
        this.budgetObject.comment = budget.comment
        this.budgetObject.budgetOverspent = budget.budgetOverspent
        this.budgetObject.overspendingRisk = budget.overspendingRisk
        this.budgetObject.objectState = budget.objectState

        return this.budgetObject
    },

    /////////////////////////////////////////////////
    //Convert All to budget Format
    /////////////////////////////////////////////////

    formatAllAsBudget(budgets) {
        let newBudgets = []

        for (let i = 0; i < budgets.length; i++) {
            newBudgets.push(globalFunctions.stringify(this.formatAsBudget(budgets[i])))
        }

        return newBudgets
    }

}




    /////////////////////////////////////////////////
    //Convert All to budget Format
    /////////////////////////////////////////////////

    export const formatAllAsBudget = (budgets) => {
        let newBudgets = []

        for (let i = 0; i < budgets.length; i++) {
            newBudgets.push(globalFunctions.stringify(budgetManager.formatAsBudget(budgets[i])))
        }

        return newBudgets
    }
