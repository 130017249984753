export const state = {
    version: '', //Cache App Version
    AccountType: '', //Account Type of Current User
    Accounts: [],
    Audit: [],
    Bills: [],
    Budgets: [],
    Categories: [],
    Currencies: [],
    CurrencyConversions: [],
    //Expenses: [],
    GCT: [],
    //Incomes: [],
    Items: [],
    Notifications: [],
    PlaceSold: [],
    Transactions: [],
    Settings: [],
    //Transfers: [],
    User: [],

  //Saves Night Mode Selection on device 
  NightMode: {
    switch: true,
    topNav: 'dark-mode',
    sideNav: 'dark-mode',
    itemCard: 'itemCarddark'
  }, 


}
