<template>
  <v-app>
      <div class="BackgroundStyle-2" style="height:100%">
          <Header></Header>
          <v-container grid-list-md class="pa-5">
              <v-layout row wrap style="padding-top:65px;">
                  <v-flex xs12 sm12 md4 text-xs-center>
                      <v-layout justify-center align-center>
                          <v-flex shrink>
                              <img src="../../public/img/icons/android-chrome-192x192.png" alt="Mww Logo" width="100%" class="img-circle" />
                          </v-flex>
                      </v-layout>
                  </v-flex>
                  <v-flex xs12 sm12 md8>

                      <div class="pa-1">
                          <span class="homeheadline grey--text"> {{ homeheadline }}  </span>
                      </div>
                      <div class="pa-1">
                          <span class="homesubheadline grey--text">{{ homesubheadline }}</span>
                      </div>

                      <!--<div>
                          <v-btn large color="teal">
                              get started
                          </v-btn>
                      </div>-->
                  </v-flex>

              </v-layout>
          </v-container>
          <v-container grid-list-xl>
              <v-layout row wrap>
                  <v-flex xs12 sm12 md4
                          v-for="card in cards"
                          :key="card.title">
                      <v-card class="elevation-5">
                          <v-img v-if="$vuetify.breakpoint.mdAndUp" v-bind:src="card.link"
                                 height="400px">
                          </v-img>
                          <v-img v-else v-bind:src="card.link"
                                 height="200px">
                          </v-img>
                          <v-card-title>
                              <div class="text-xs-center">
                                  <div class="headline"> {{ card.title }} </div>
                                  <span class="grey--text"> {{ card.featureDescription }}</span>
                              </div>
                          </v-card-title>
                      </v-card>
                  </v-flex>
              </v-layout>
          </v-container>
      </div>
  </v-app>
</template>

<script>

    import header from '../components/header.vue'
    import { auth } from '../auth/index'

  export default {
    components: {
      'Header': header
    },
    data() {
      return {
        homeheadline: 'Budget your way',
          homesubheadline: 'Let us help you keep an eye on your wallet',
        cards: [
            { title: 'Budgeting', link: './img/thumbnails/triangle-1280x728.jpg', featureDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi nec dolor et sapien ultrices suscipit suscipit' },
            { title: 'Expense Tracking', link: './img/thumbnails/wallet-1280x833.webp', featureDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi nec dolor et sapien ultrices suscipit suscipit' },
            { title: 'Reports', link: './img/thumbnails/accountant-1280x856.webp', featureDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi nec dolor et sapien ultrices suscipit suscipit' }
        ]
        

      }
    },
    beforeMount() {
        if (window.matchMedia('(display-mode: standalone)').matches /*|| auth.checkAuth*/) {

        //Redirect to Dashboard if App is installed
        this.$router.push({ path: '/dashboard' })
      }
     
    }

  }
</script>
<style scoped>
  * {
    font-family: Calibri !important;
  }

  .homeheadline {
    font-size: 48px;
  }

  .homesubheadline {
    font-size: 24px;
    line-height: 40px;
  }
</style>
