<template>
  <div>
    <v-app-bar text dark class="BackgroundStyle-2" fixed>

      <v-toolbar-title ><span><span style="color: orange;">MyWallet</span>Watcher</span></v-toolbar-title>

      <v-spacer></v-spacer>
      <v-btn text @click.prevent="$router.push({ path: '/dashboard' })" class="hidden-sm-and-down">
        Dashboard
      </v-btn>
      <v-btn text @click.prevent="$router.push({ path: '/login' })" class="hidden-sm-and-down">
        Login
      </v-btn>
      <v-btn outlined color="orange" @click.prevent="$router.push({ path: '/Registration' })" class="hidden-sm-and-down">
        Sign up
      </v-btn>

      <v-btn text @click.prevent="drawer = !drawer" class="hidden-md-and-up">
        <v-icon>fas fa-bars</v-icon>
      </v-btn>

    </v-app-bar>
    <v-navigation-drawer v-model="drawer"
                         absolute
                         temporary
                         class="BackgroundStyle-2"
                         dark>
        <v-list subheader>
            <v-subheader><span style="font-size:20px"><span style="color: orange;">MyWallet</span>Watcher</span></v-subheader>
            <v-divider></v-divider>
            <v-list-item-group>
                <v-list-item @click.prevent="$router.push({ path: '/dashboard' })"> <v-list-item-content>Dashboard</v-list-item-content></v-list-item>
                <v-list-item @click.prevent="$router.push({ path: '/login' })"><v-list-item-content>Login</v-list-item-content></v-list-item>
                <v-list-item @click.prevent="$router.push({ path: '/registration' })"><v-list-item-content>Registration</v-list-item-content></v-list-item>
            </v-list-item-group>
        </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
  export default {
    data(){
      return {
        drawer: false
      }
    }
  }
</script>
